import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {NavigationEnd} from '@angular/router';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-menu-revision',
  templateUrl: './menu-revision.component.html',
  styleUrls: ['./menu-revision.component.scss']
})
export class MenuRevisionComponent extends BaseComponent {

  concepto_jojo = new FormControl('');

  question: any;

  questionToken: any;
  nextQuestion: any;
  isShow: boolean = false;

  questionActive: boolean = false;

  questionAllActive: boolean = true;

  questionIndex = 0;

  questionCount = 0;

  questionConcept = "";

  questionData: any;

  questionTokenTemp: any;

  public isEvaluation: boolean = false;

  ngAfterViewChecked() {
    console.log("adios");

    this.questions = this.trainingService.getCurrentQuestions();
    this.questionCount = this.questions.length;

    if (this.questionCount > 0){

      this.questionAllActive = this.questions[0].active;
    }

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {

        this.isShow = true;

       this.questionToken =  this.router.routerState.snapshot.root.firstChild.params["token"];

       if (this.questionTokenTemp != this.questionToken){

         this.questionTokenTemp = this.questionToken;

         const questionToken = this.questionToken;

        console.log( "questionToken " + questionToken);

        var f: number;

        this.question = this.questions.find(function(item, index) {
          f = index + 1;
          return item.token === questionToken;
        });

        this.questionIndex = this.question.position;

        if (this.question){
            if (this.question.active) {
              this.questionActive = true;
            }else{
              this.questionActive = false;
            }


            this.trainingService.getConcept(this.questionToken).subscribe(data=>{

                this.questionData = data;
               this.concepto_jojo.setValue(this.questionData.ok);
               //this.questionConcept = this.questionData.ok;
            });

        }
       }


      }
    });

  }

  onReload(){
     window.scroll(0,0);
    this.spinnerService.show();

    this.trainingService.getReload().subscribe(
      datax => {

         this.trainingService.getFeed().subscribe(
          data => {
            this.trainingService.setFeed(data);

             window.location.reload();

            setTimeout(() => {
              this.spinnerService.hide();
            }, 1000);
          });


    });
  }
  saveConcept(concept){
    console.log(concept);
    this.trainingService.saveConcept(this.questionToken, concept).subscribe(data=>{

    });

  }

  onAllReload(){
     window.scroll(0,0);
    this.spinnerService.show();

    if (this.questionAllActive){

      this.trainingService.showDisableQuestions().subscribe(data=>{
           console.log(data);

            this.location.go(`/`);
            location.reload();
      });

    }else{
     this.trainingService.showEnableQuestions().subscribe(data=>{
          console.log(data);
           this.location.go(`/`);
           location.reload();
      });
    }
  }

  onActivarDesactivar(){
    console.log(this.questionActive);
     window.scroll(0,0);
    this.spinnerService.show();

    if (this.questionActive){
       this.trainingService.disableQuestion(this.questionToken).subscribe(data=>{
           console.log(data);

            this.location.go(`/`);
                location.reload();
      });

    }else{
     this.trainingService.enableQuestion(this.questionToken).subscribe(data=>{
          console.log(data);
           this.location.go(`/`);
                location.reload();
      });
    }

  }

  onConcepto(){

  }


  ngOnInit() {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

     if (this.trainingService.isEvaluation() == "1"){
      this.isEvaluation = true;
    }

  }


  onBack(){
     this.concepto_jojo.setValue('');
    window.scroll(0,0);
    this.spinnerService.show();


    const questionToken = this.questionToken;

    var f: number;

    this.question = this.questions.find(function(item, index) {
      f = index - 1;
      return item.token === questionToken;
    });

       var totalQuestions;

    totalQuestions = this.questions.length - 1;
    console.log(this.questions.length);
    console.log(f);

    if (f == -1){
      f = totalQuestions;
    }

    if (f <= totalQuestions){
      console.log("xxx");
      this.nextQuestion = this.questions[f].token;

      setTimeout(()=>{
        this.spinnerService.hide();
      }, 500);
       window.scroll(0,0);
       if (this.questions[f].type_question == "pildora"){

         this.router.navigate([`/training/question/${this.nextQuestion}/pildora`]);
       }else{
          this.router.navigate(["/training/question", this.nextQuestion]);

       }

    }

  }

  onEditar(questionToken){

    var url = "https://wiserteams.com/get_resolve_question/" + questionToken;
		window.open(url);

  }

  onNext(){
     this.concepto_jojo.setValue('');
     window.scroll(0,0);
    this.spinnerService.show();

    const questionToken = this.questionToken;

    var f: number;

    this.question = this.questions.find(function(item, index) {
      f = index + 1;
      return item.token === questionToken;
    });


    var totalQuestions;

    totalQuestions = this.questions.length - 1;
    console.log(this.questions.length);
     console.log("totalQuestions " + totalQuestions);
    console.log(f);

    if (f > totalQuestions){
      f = 0;
    }

    if (f <= totalQuestions){

      console.log("xxx");
      this.nextQuestion = this.questions[f].token;

      setTimeout(()=>{
        this.spinnerService.hide();
      }, 500);
       window.scroll(0,0);

        if (this.questions[f].type_question == "pildora"){

         this.router.navigate([`/training/question/${this.nextQuestion}/pildora`]);
       }else{
          this.router.navigate(["/training/question", this.nextQuestion]);
          }

    }else{
      console.log("ssss");
    }

  }

}
