import { Component, OnInit } from '@angular/core';
import {PlanComponent} from '../plan/plan.component';

@Component({
  selector: 'app-filter-plan',
  templateUrl: './filter-plan.component.html',
  styleUrls: ['./filter-plan.component.scss']
})
export class FilterPlanComponent extends PlanComponent {

 

  ngOnInit() {
  	this.getBgcolor();
  	this.getFocusAll();
  }

}
