import { Component, OnInit } from '@angular/core';
import {PlanComponent} from '../../growth/plans/plan/plan.component';

@Component({
  selector: 'app-users-availables',
  templateUrl: './users-availables.component.html',
  styleUrls: ['./users-availables.component.scss']
})
export class UsersAvailablesComponent extends PlanComponent {


  public users: any;
  public user_chat: any;
   public account: any;
   public user: any;

  public chats:  any = {
    user_two: {
      name: "",
      id: 0
    },
    chats: []
  };


   openChat(user_id){
       //this.modalReference = this.modalService.open(content);
      window.scroll(0,0);



    this.router.navigate(["/fundae/chat", {user_chat: user_id}]);

  }

  ngOnInit() {

    window.scroll(0,0);
    this.getBgcolor();
    this.spinnerService.show();

    this.user_chat = this.route.snapshot.paramMap.get('user_chat');
    this.account = this.trainingService.getCurrentAcount();
    this.user = this.trainingService.getCurrentUserPublic();

    this.trainingService.getUserAvailables().subscribe(data => {

        this.users = data;

        setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);


    });



  }

  applyStyles() {
    const styles = {'background-color' :  this.getBgcolor1(), 'position':'fixed', 'bottom':'0px','width': '100%', 'max-width':'700px', 'padding-left':'0px'};
    return styles;
  }

}
