import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent extends BaseComponent{
  surveys: any;
  account: any;
  fullname: any;
  dataDashboard: any;
  user: any;
  messages: any;

  pending_tests: any;
  pending_pills: any;
  pending_surveys: any;
  pending_brainstormings: any;
  pending_objetives: any;
  pending_evaluations: any;
  pending_valorations: any;
  feed: any;
  questions: any;


  questions_count: any;
  surveys_count: any;
  brainstormings_count: any;
  evaluations_count: any;

  text_acertados: any;
  text_ranking: any;
  text_contestadas: any;
  text_ideas_generadas: any;
  text_puntos_totales: any;
  text_extra: any;

  pending_tests_course_text_feed: any;
  tests_repetition_text_feed: any;
  finished_course_text_feed: any;
  compromise: any;
  dashboard_bg_color: any;
  dashboard_font_color: any
  titulo1: any;
  titulo2: any;

  applyStyles() {
    //console.log("bg2 " + this.getBgcolor1());
    const styles = {'background-color' :  this.getBgcolor1(), 'position':'fixed', 'bottom':'0px','width': '100%', 'max-width':'700px', 'padding-left':'0px'};
    return styles;
  }

  ngOnInit() {


    window.scroll(0,0);
    this.spinnerService.show();

     this.authService.logoutUser();
    this.location.go("?logout");
    location.reload();


    this.getBgcolor();


  }

  onNext(){

    this.spinnerService.show();
    this.trainingService.getFeed().subscribe(
          data => {
            console.log(data);
            this.trainingService.setFeed(data);

            this.feed = this.trainingService.getCurrentFeed();

            if (this.feed.questions.length == 0) {

              if (this.feed.surveys.length == 0) {
                if (this.feed.add_brainstormings.length == 0) {
                    setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);
                  alert("No tienes contenidos pendientes para hoy");

                } else {
                    setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);
                  this.calculateNextView();
                }
              } else {
                  setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);
                this.calculateNextView();
              }

            } else {
                setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);
              this.calculateNextView();
            }
          });
  }
}
