import { Injectable } from '@angular/core';

import { LoginInterface } from '../models/login-interface';

import { isNullOrUndefined } from "util";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class BaseService {

  constructor(public http: HttpClient) {}
	
  headers: HttpHeaders = new HttpHeaders({
	    "Content-Type": "application/json",
      "Authorization": `Basic ${this.getToken()}`
	});

	user: Observable<any>;

	getEndPoint(){
    //return "https://wiserteams.com";
    //return "http://localhost:3000";
    return "https://old.wiserteams.com";
  }

  setBgcolor(bg1: string, bg2: string): void {
    localStorage.setItem("bgcolor1", bg1);
    localStorage.setItem("bgcolor2", bg2);
  }

  setFontSizePx(fs): void {
    localStorage.setItem("font_size", fs);

  }





	getToken(){
    return localStorage.getItem("accessToken");
  }

  getCourseToken(){
    return localStorage.getItem("accessCourseToken");
  }

   getCourseCycle(){
    return localStorage.getItem("courseCycle");
  }


  isAdmin() {
    return localStorage.getItem("currentAdmin");
  }

  isRevision() {
    return localStorage.getItem("currentRevision");
  }

  getTokenDevice(){
    return localStorage.getItem("currentTokenDevice");
  }



  getGroup(){
    return localStorage.getItem("currentGroup");
  }

  getDevice(){
    return localStorage.getItem("currentDevice");
  }

  isFranally(){
    return localStorage.getItem("isFranally");
  }

  setCurrentObjetive(objetive): void {
    let objetive_string = JSON.stringify(objetive);

    localStorage.setItem("currentObjetive", objetive_string);
  }

   getCurrentObjetive() {
      let objetive_string = localStorage.getItem("currentObjetive");
      if (!isNullOrUndefined(objetive_string)) {
        let objetive = JSON.parse(objetive_string);
        return objetive;
      } else {
        return null;
      }
  }

  getCurrentUserPublic() {
      let user_public_string = localStorage.getItem("currentUserPublic");
      if (!isNullOrUndefined(user_public_string)) {
        let user_public = JSON.parse(user_public_string);
        return user_public;
      } else {
        return null;
      }
  }


  getCurrentUser(): LoginInterface {
      let user_string = localStorage.getItem("currentUser");
      if (!isNullOrUndefined(user_string)) {
        let user: LoginInterface = JSON.parse(user_string);
        return user;
      } else {
        return null;
      }
  }

  getCurrentFeed() {
      let feed_string = localStorage.getItem("currentFeed");
      if (!isNullOrUndefined(feed_string)) {
        let feed = JSON.parse(feed_string);
        return feed;
      } else {
        return null;
      }
  }

  getCurrentQuestions() {
      let questions_string = localStorage.getItem("currentQuestions");
      if (!isNullOrUndefined(questions_string)) {
        let questions = JSON.parse(questions_string);
        return questions;
      } else {
        return null;
      }
  }

  getCurrentSurveys() {
      let surveys_string = localStorage.getItem("currentSurveys");
      if (!isNullOrUndefined(surveys_string)) {
        let surveys = JSON.parse(surveys_string);
        return surveys;
      } else {
        return null;
      }
  }

  getCurrentBrainstormings() {
      let add_brainstormings_string = localStorage.getItem("currentAddBrainstormings");
      if (!isNullOrUndefined(add_brainstormings_string)) {
        let add_brainstormings = JSON.parse(add_brainstormings_string);
        return add_brainstormings;
      } else {
        return null;
      }
  }

  getCurrentNotices() {
      let notices_string = localStorage.getItem("currentNotices");
      if (!isNullOrUndefined(notices_string)) {
        let notices = JSON.parse(notices_string);
        return notices;
      } else {
        return null;
      }
  }

  getCurrentLeaderboard() {
      let leaderboard_string = localStorage.getItem("currentLeaderboard");
      if (!isNullOrUndefined(leaderboard_string)) {
        let leaderboard = JSON.parse(leaderboard_string);
        return leaderboard;
      } else {
        return null;
      }
  }

  getCurrentAcount() {
      let account_string = localStorage.getItem("currentAccount");
      if (!isNullOrUndefined(account_string)) {
        let account = JSON.parse(account_string);
        return account;
      } else {
        return null;
      }
  }

  logoutUser() {
    let accessToken = localStorage.getItem("accessToken");
    
    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentUserPublic");
    localStorage.removeItem("currentAccount");
    localStorage.removeItem("bgcolor1");
    localStorage.removeItem("bgcolor2");

    return null;
	}
}
