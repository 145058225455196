import { Component, OnInit } from '@angular/core';

import {PlanComponent} from '../plan/plan.component';

@Component({
  selector: 'app-finished-plans',
  templateUrl: './finished-plans.component.html',
  styleUrls: ['./finished-plans.component.scss']
})

export class FinishedPlansComponent  extends PlanComponent {

  ngOnInit() {
  	this.getBgcolor();
  	 this.getFinishedPlans();
  }

}
