import { Component, OnInit } from '@angular/core';

import {PlanComponent} from '../plan/plan.component';

@Component({
  selector: 'app-list-plan',
  templateUrl: './list-plan.component.html',
  styleUrls: ['./list-plan.component.scss']
})
export class ListPlanComponent extends PlanComponent {

 
  bgcolor10 = '';


  ngOnInit() {
    window.scroll(0,0);
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
    };

    this.setTitle("Planes");

     console.log(this.route.snapshot.queryParams['t']);

    if (this.route.snapshot.queryParams['t'])
    {

      console.log("hola t");
      
    }

   this.getBgcolor();

    console.log("COLOR 1 " + this.dataApiService.getBgcolor1());
    console.log("COLOR 2 " + this.dataApiService.getBgcolor2());




    this.getListPlansStarted();
    this.getListPlansOptional();

    this.spinnerService.show();
    this.dataApiService
    .getValorationPlans()
    .subscribe((plans) => {
      this.plansValoration = plans;
         setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);

    });

    
  }


 

}
