import { Component, OnInit, ElementRef } from '@angular/core';

import {PlanComponent} from '../plan/plan.component';

@Component({
  selector: 'app-valoration-plan',
  templateUrl: './valoration-plan.component.html',
  styleUrls: ['./valoration-plan.component.scss']
})

export class ValorationPlanComponent extends PlanComponent {

  action_id: '';
  valoration_id: '';
  public title: "";

  ngOnInit() {
    this.setTitle("Valoración");
    this.spinnerService.show();

    this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
    };

    this.setTitle("Valoración:");
  	this.getBgcolor();
    this.action_id = this.route.snapshot.params['id'];
    

    this.getDetailValoration(this.action_id);
    
  }


  

}
