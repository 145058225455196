import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent extends BaseComponent{
  surveys: any;
  survey: any;
  surveyIndex: any;

  qtitle: string;
  suptitle: string;

  optionSelected: string;
  checkedList = [];

  maxItems = 0;

  ngOnInit() {

    this.setTitle("Encuesta");

    const surveyToken = this.route.snapshot.params['token'];

    this.surveys = this.trainingService.getCurrentSurveys();

    var f: number;

    this.survey = this.surveys.find(function(item, index) {
      f = index;
      return item.token === surveyToken;
    });

    this.surveyIndex = f;

    this.maxItems = this.survey.max_items;

    console.log("items " + this.maxItems);

    this.getBgcolor();

    var q: any;

    q = this.formatTitle(this.survey.question);

    this.qtitle = q.title;
    this.suptitle = q.suptitle;


    this.account = this.trainingService.getCurrentAcount();

  }

  answerSurvey(){
    this.spinnerService.show();
    console.log(this.surveyIndex);
    console.log(this.surveys.length);

    var actual = (this.surveys.length - 1);
    var nextObj = this.surveyIndex + 1;


     this.trainingService.removeSurvey(this.survey);

    if (this.survey.multiple) {
      window.scroll(0, 0);
      var answers;

      answers = this.checkedList.join(',');

      if (answers) {

        this.trainingService.answeredSurveyMultiple(this.survey.token, answers).subscribe(
          data => {
            console.log(data);

            window.scroll(0, 0);
            if (actual > this.surveyIndex) {



              this.router.navigate(['/training/contributions/survey/', this.surveys[nextObj].token]);
              setTimeout(() => {
                this.spinnerService.hide();
              }, 500);
              //window.location.href = "/training/contributions/survey/" + this.surveys[actual].token ;

            } else {


              setTimeout(() => {
                this.spinnerService.hide();
              }, 500);
              this.calculateNextView();
            }


          });

      } else {
        alert('Seleccione al menos una opción');

        setTimeout(() => {
          this.spinnerService.hide();
        }, 500);

        return false;
      }

    } else {

      if (this.optionSelected) {
        this.trainingService.answeredSurveyNormal(this.survey.token, this.optionSelected).subscribe(
          data => {
            console.log(data);
            window.scroll(0, 0);
            if (actual > this.surveyIndex) {


              this.router.navigate(['/training/contributions/survey/', this.surveys[nextObj].token]);


              setTimeout(() => {
                this.spinnerService.hide();
              }, 500);

              //window.location.href = "/training/contributions/survey/" + this.surveys[actual].token ;

            } else {


              setTimeout(() => {
                this.spinnerService.hide();
              }, 500);
              this.calculateNextView();
            }
          });
      } else {
        alert('Seleccione una opción');
        setTimeout(() => {
          this.spinnerService.hide();
        }, 500);

        return false;
      }


    }

  }

  optionMultiple(event: any){
    var _this = this;
    console.log(this.checkedList.length);
    var go_s = true;

    if ((this.checkedList.length + 1) > this.maxItems ){
      go_s = false;
    }

    if (this.maxItems === 0){
      go_s = true;
    }


    if (go_s){
      if(event.target.checked) {
      this.checkedList.push(event.target.value);
    }else{

      var value;
      var f: any;

      f = -1;

      value = event.target.value;

      var xquestion = this.checkedList.find(function(item, index) {
        f = index;
        return item  == value;
      });

      if (f != -1){

        this.checkedList.splice(f,1);
      }

    }
    }else{
      event.target.checked = false;

      alert("Solo se puede seleccionar " + this.maxItems + " respuestas");
    }


    console.log(this.checkedList);


  }
  optionSingle(event: any){

    if(event.target.checked) {
      this.optionSelected = event.target.value;
       console.log(this.optionSelected);
    }

  }

  superTitle(){
    return this.suptitle;
  }

  applyStyles() {
    //console.log("bg2 " + this.getBgcolor1());
    const styles = {'background-color' :  this.getBgcolor1(), 'position':'fixed', 'bottom':'0px','width': '100%', 'max-width':'700px', 'padding-left':'0px'};
    return styles;
  }

}
