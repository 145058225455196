import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';

@Component({
  selector: 'app-pre-objetive',
  templateUrl: './pre-objetive.component.html',
  styleUrls: ['./pre-objetive.component.scss']
})
export class PreObjetiveComponent extends BaseComponent {

  ngOnInit() {
    window.scroll(0,0);
    this.setTitle("Objetivos");
    this.getBgcolor();
  }


}
