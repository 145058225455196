import { Component, OnInit } from '@angular/core';

import {PlanComponent} from '../plan/plan.component';

@Component({
  selector: 'app-more-plans',
  templateUrl: './more-plans.component.html',
  styleUrls: ['./more-plans.component.scss']
})

export class MorePlansComponent extends PlanComponent {


  ngOnInit() {
    window.scroll(0,0);
    this.getListPlansOptional();
  }


}
