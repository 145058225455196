import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ObjetiveService extends BaseService{

  getObjetives(mode: string = 'normal'){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/action_plans.json?_nocache=${(new Date).getTime()}&lang=es&type=${mode}`;

    return this.http
      .get(
        url_api,
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  addPlan( plan, plan_pogress, end_date, plan_finished, details, type_objetive){
       const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/add_action.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        {
          plan: plan,
          plan_pogress: plan_pogress,
          end_date: end_date,
        plan_finished: plan_finished,
        details: details,
        type_objetive: type_objetive},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  updatePlan(token, plan, plan_pogress, comment){
      const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/update_action.json?_nocache=${(new Date).getTime()}`;

      return this.http
      .post(
        url_api,
        { token: token,
          plan: plan,
          plan_pogress: plan_pogress,
          comment: comment},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  deletePlan(token){
       const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/delete_action.json?_nocache=${(new Date).getTime()}`;

      return this.http
      .post(
        url_api,
        { token: token},
        { headers: this.headers }
      )
      .pipe(map(data => data));

  }

}
