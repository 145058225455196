import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-brainstorming',
  templateUrl: './brainstorming.component.html',
  styleUrls: ['./brainstorming.component.scss']
})
export class BrainstormingComponent extends BaseComponent {
  brainstormings: any;
  brainstorming: any;
  brainstormingIndex: any;

  qtitle: string;
  suptitle: string;

  speechText = new FormControl('');

  openO = false;

  openMic(){

    if (this.openO){
      this.openO = false;
      window.location.href = "close://microphone";
    }else{
      this.openO = true;
      window.location.href = "open://microphone";
    }
  }

  placeHolderComment(){

    if (this.speechText.value == "Toca el icono para iniciar..."){
      this.speechText.setValue("");
    }else{
       console.log("sss2");
    }
  }

  autogrow() {
    let textArea = document.getElementById("comments")
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  writeMessage(message){
    this.speechText.setValue(message);
    this.autogrow();
  }

 isDevice = false;

  ngOnInit() {
    this.setTitle("Brainstorming");

      this.trainingService.setViewedBrainstorming(1);

       this.account = this.trainingService.getCurrentAcount();


    if (this.trainingService.getDevice()){
      this.isDevice = true;
    }
    const brainstormingToken = this.route.snapshot.params['token'];

    this.brainstormings = this.trainingService.getCurrentBrainstormings();

    var f: number;

    this.brainstorming = this.brainstormings.find(function(item, index) {
      f = index;
      return item.token === brainstormingToken;
    });

    this.brainstormingIndex = f;

    this.getBgcolor();

     window.writeMessage = this.writeMessage.bind(this);

    this.speechText.setValue("Toca el icono para iniciar...");

    var q: any;

    q = this.formatTitle(this.brainstorming.question);

    this.qtitle = q.title;
    this.suptitle = q.suptitle;

    if (this.brainstorming.image.url){
      this.setDimensions(`https://old.wiserteams.com${this.brainstorming.image.url}`, "#brain_image", 0.26);

    }

  }
  superTitle(){
    return this.suptitle;
  }

  answerBrainstorming(){

    this.spinnerService.show();
    var content = this.speechText.value;
    var token = this.brainstorming.token;
    this.trainingService.removeBrainstorming(this.brainstorming);
    console.log(this.brainstormingIndex);
    console.log(this.brainstormings.length);
    var actual = (this.brainstormings.length - 1);
    var nextObj = this.brainstormingIndex + 1;

      this.trainingService.answeredBrainstorming(token, content).subscribe(
          data => {
              console.log(data);




              if (actual > this.brainstormingIndex){
   setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);
                 this.router.navigate(["/training/contributions/brainstorming/", this.brainstormings[nextObj].token]);

                //window.location.href = "/training/contributions/brainstorming/" + this.brainstormings[actual].token ;

              }else{
                console.log("paseee");
                this.calculateNextView();
                 setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);
              }
        });
  }

  applyStyles() {
    //console.log("bg2 " + this.getBgcolor1());
    const styles = {'background-color' :  this.getBgcolor1(), 'position':'fixed', 'bottom':'0px','width': '100%', 'max-width':'700px', 'padding-left':'0px'};
    return styles;
  }

}
