import { Component, OnInit } from '@angular/core';
import {PlanComponent} from '../plan/plan.component';
import {SurveyInterface} from '../../../../models/survey-interface';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-antes-survey',
  templateUrl: './antes-survey.component.html',
  styleUrls: ['./antes-survey.component.scss']
})
export class AntesSurveyComponent  extends PlanComponent{

  survey: any;


  index = 0;

   optionSelected: string;
  checkedList = [];

  ngOnInit() {
    window.scroll(0,0);

    this.spinnerService.show();

    this.getBgcolor();

    const plan_id = this.route.snapshot.params['id'];

    this.index = this.route.snapshot.params['index'];

    this.dataApiService.getPlanById(plan_id).subscribe(plan => {
      this.plan = plan;

      this.brainstormings = (<any>this.plan).antes.brainstormings;
      this.surveys = (<any>this.plan).antes.surveys;
      this.valorations = (<any>this.plan).antes.valorations;

      this.survey = this.surveys[this.index];

      setTimeout(()=>{
        this.spinnerService.hide();
      }, 500);
    });

  }


  answerSurvey(){
    this.spinnerService.show();


    if (this.survey.multiple) {
      window.scroll(0, 0);
      var answers;

      answers = this.checkedList.join(',');

      if (answers) {

        this.trainingService.answeredSurveyMultiple(this.survey.token, answers).subscribe(
          data => {
            console.log(data);

            window.scroll(0, 0);

            if (this.surveys.length  <= (this.index + 1)){


                window.scroll(0,0);
                this.router.navigate([`growth/plan/${(<any>this.plan).token}/value/0/valorations`]);


            }else{

               var sum  = (parseInt(<any>this.index) + 1);

              return this.router.navigate([`growth/plan/${(<any>this.plan).token}/value/${sum}/surveys`]);
            }

          });

      } else {
        alert('Seleccione al menos una opción');

        setTimeout(() => {
          this.spinnerService.hide();
        }, 500);

        return false;
      }

    } else {

      if (this.optionSelected) {
        this.trainingService.answeredSurveyNormal(this.survey.token, this.optionSelected).subscribe(
          data => {
            console.log(data);
            window.scroll(0, 0);


            if (this.surveys.length  <= (this.index + 1)){


                window.scroll(0,0);
                this.router.navigate([`growth/plan/${(<any>this.plan).token}/value/0/valorations`]);


            }else{

              var sum  = (parseInt(<any>this.index) + 1);

              return this.router.navigate([`growth/plan/${(<any>this.plan).token}/value/${sum}/surveys`]);
            }

          });
      } else {
        alert('Seleccione una opción');
        setTimeout(() => {
          this.spinnerService.hide();
        }, 500);

        return false;
      }


    }

  }

  optionMultiple(event: any){
    var _this = this;

    if(event.target.checked) {
      this.checkedList.push(event.target.value);
    }else{

      var value;
      var f: any;

      f = -1;

      value = event.target.value;

      var xquestion = this.checkedList.find(function(item, index) {
        f = index;
        return item  == value;
      });

      if (f != -1){

        this.checkedList.splice(f,1);
      }

    }

    console.log(this.checkedList);


  }
  optionSingle(event: any){

    if(event.target.checked) {
      this.optionSelected = event.target.value;
       console.log(this.optionSelected);
    }

  }
}
