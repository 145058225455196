import { Component, OnInit } from '@angular/core';
import {PlanComponent} from '../plan/plan.component';


@Component({
  selector: 'app-objetives',
  templateUrl: './objetives.component.html',
  styleUrls: ['./objetives.component.scss']
})
export class ObjetivesComponent extends PlanComponent  {

  ngOnInit() {
    this.setTitle("Acciones");
    window.scroll(0,0);
  	this.getBgcolor();
  	// this.getObjetivesAll(1);
  	this.getAllObjetives();
  	 
  }

}
