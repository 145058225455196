import {Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';

@Component({
  selector: 'app-form-objetive',
  templateUrl: './form-objetive.component.html',
  styleUrls: ['./form-objetive.component.scss']
})

export class FormObjetiveComponent extends BaseComponent{

  @ViewChild('text_objetive_edit') text_objetive_edit;


  objetive: any;
  opt: any;

  text_objetive: any;
  plan_pogress: any;
  plan_finished: any;
  plan_percentage: any;
  comment: any;
  details: any;

  plan_0: any= 0;
  plan_25: any= 25;
  plan_50: any= 50;
  plan_75: any= 75;
  plan_100: any= 100;

  isClicked = true;

  onPlus(){
    this.plan_pogress = this.plan_pogress + 1;
    this.plan_percentage = ((this.plan_pogress/this.plan_finished)*100).toFixed(0);
  }

  ngOnInit() {
    window.scroll(0,0);
    this.setTitle("Objetivos");
    const objetiveToken = this.route.snapshot.params['token'];

    this.getBgcolor();

    this.objetive = this.trainingService.getCurrentObjetive();

    console.log(objetiveToken);

    this.opt = this.objetive.type_objetive;
    this.text_objetive = this.objetive.plan;
    this.plan_pogress = this.objetive.plan_pogress;
    this.plan_finished = this.objetive.plan_finished;
    this.details = this.objetive.details;

    this.text_objetive_edit.nativeElement.disabled = true;

     switch (this.opt) {
       case 'numerico':
         this.plan_percentage = ((this.plan_pogress / this.plan_finished) * 100).toFixed(0);
         break;

     }

  }

  ngAfterContentChecked(): void {
    let  textArea = document.getElementById("xtitle_action");
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 10 + 'px';
  }

  autogrow(){
    let  textArea = document.getElementById("xtitle_action");
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  toogleEdit(){
    this.text_objetive_edit.nativeElement.disabled = false;
    this.text_objetive_edit.nativeElement.style.backgroundColor = "#a8adaf";
    this.text_objetive_edit.nativeElement.focus();
  }

  goBack(){
    window.scroll(0,0);
    this.router.navigate([`/training/objetives/list`]);
  }

  checkBlock(compare_value){
    if (this.opt == compare_value){
      return true;
    }else{
      return false;
    }
  }

  onSave(){
    window.scroll(0, 0);
    console.log(this.text_objetive);

    console.log(this.plan_pogress);

    console.log(this.plan_finished);

    console.log(this.comment);

    var plan = this.text_objetive;

    var type_objetive = this.opt;

    var text_comment_action = this.comment;
    var token = this.objetive.token;
    var plan_pogress2 = 0;

    switch (type_objetive) {
      case 'numerico':
        plan_pogress2 = this.plan_pogress;

        if (!plan_pogress2) {
          alert('Agrege un numero dandole al simbolo +');
          return false;
        }

        break;
      case 'normal':
        plan_pogress2 = this.plan_pogress;
        if (!plan_pogress2) {
          alert('Agrege un porcentaje del avance');
          return false;
        }
        break;
      case 'parciales':
        plan_pogress2 = this.plan_pogress;
        console.log(plan_pogress2);

        if (!plan_pogress2) {
          alert('Agrege un porcentaje del avance');
          return false;
        }
        break;
      case 'si_no':
        plan_pogress2 = this.plan_pogress;
        if (!plan_pogress2) {
          alert('Agrege un porcentaje del avance');
          return false;
        }
        break;
    }


    this.spinnerService.show();

    this.objetiveService.updatePlan(this.objetive.token, this.text_objetive, this.plan_pogress, this.comment).subscribe(data => {

      setTimeout(() => {
        this.spinnerService.hide();

        this.router.navigate([`/training/objetives/list`]);

      }, 500);
    });
  }

  setPlanProgress(): void{
    this.plan_pogress = 100;
  }

  onDelete() {

    this.confirmationDialogService.confirm('Necesita confirmación', '¿Estás realmente seguro que quieres borrar este OBJETIVO?', 'SI', 'NO')
      .then((confirmed) => {
        if (confirmed) {

          window.scroll(0, 0);
          this.spinnerService.show();

          this.objetiveService.deletePlan(this.objetive.token).subscribe(data => {
            setTimeout(() => {
              this.spinnerService.hide();

              this.router.navigate([`/training/objetives/list`]);

            }, 500);
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  }

}
