import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../shared/base/base.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent  extends BaseComponent{
  applyStyles() {
    //console.log("bg2 " + this.getBgcolor1());
    const styles = {'background-color' :  this.getBgcolor1(), 'position':'fixed', 'bottom':'0px','width': '100%', 'max-width':'700px', 'padding-left':'0px'};
    return styles;
  }

  ngOnInit() {
      window.scroll(0,0);
      this.getBgcolor();
  }

  onSentNotification(){

    this.trainingService.getTestNotification().subscribe(data => {

      console.log("---");
      alert("En breve se le enviara una notificación de prueba");

    });

  }

}
