import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {FormControl} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent extends BaseComponent{

  public user: any;

  public chats:  any = {
    user_two: {
      name: "",
      id: 0
    },
    chats: []
  };

  public users:  any = {
    fullname: "",
    token: ""
  };


   writeMsg = new FormControl('');

    public isAdmin: boolean = false;

    public myGroup = "";


   openSm(content) {
    this.modalService.open(content, { size: 'sm' });
  }

  public user_chat: any;

   totalTime: any;

  ngOnInit() {

    window.scroll(0, 0);
    this.totalTime = new Date();
    this.spinnerService.show();
    this.user_chat = this.route.snapshot.paramMap.get('user_chat');
    this.account = this.trainingService.getCurrentAcount();
    this.user = this.trainingService.getCurrentUserPublic();

     if ( this.authService.isAdmin() == "1"){
        this.isAdmin = true;
      }else{
        this.isAdmin = false;
      }

    this.myGroup = this.authService.getGroup();

    this.setTitle("CHAT");

    this.getBgcolor();

    this.chatService.getChats(this.user_chat, this.user.group_fundae_id).subscribe(data => {

          this.chats = data;
          this.chatService.getContacts(this.user.group_fundae_id).subscribe(data => {
            this.users = data;
            setTimeout(() => {
              this.spinnerService.hide();
            }, 500);

          });
      });
  }

  onSendChat(){
     //window.scroll(0, 0);
    window.scrollTo(0,document.body.scrollHeight);

    this.spinnerService.show();

    var content = this.writeMsg.value;

    this.writeMsg.setValue("");

    var timeNow: any;
    timeNow = new Date();
    var currentTime =  Math.floor((timeNow -  this.totalTime)/1000);

    this.chatService.addChat(content, this.user_chat, this.user.group_fundae_id, currentTime).subscribe(data => {
        this.chats = data;
         setTimeout(() => {
              this.spinnerService.hide();
            }, 500);
    });
  }

  backClicked(){
    this.location.back();
  }

  autogrow() {
    console.log("hola");
    let write_msg = document.getElementById('write_msg');
    write_msg.style.overflow = 'hidden';
    write_msg.style.height = '0px';
    write_msg.style.height = write_msg.scrollHeight + 'px';
  }

  openChat(user_id){
    //this.modalReference = this.modalService.open(content);
    window.scroll(0,0);
    this.modalService.dismissAll();
    this.router.navigate(["/fundae/chat", {user_chat: user_id}]);
  }

  applyStyles() {
    const styles = {'background-color' :  this.getBgcolor1(), 'position':'fixed', 'bottom':'0px','width': '100%', 'max-width':'700px', 'padding-left':'0px'};
    return styles;
  }
}
