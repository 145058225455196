import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent extends BaseComponent {
  notices: any;


  openForum(notice) {
    this.spinnerService.show();

    window.scroll(0,0);

    var tokenNotice = notice.token;

    this.trainingService.getReadNotice(tokenNotice).subscribe(data => {

        this.location.go(`/fundae/forum/${notice.conector_id}`);
        location.reload();

      setTimeout(()=>{
            this.spinnerService.hide();
          }, 500);

    });

  }

  openChat(notice){

    this.spinnerService.show();

    window.scroll(0,0);

    var tokenNotice = notice.token;

    this.trainingService.getReadNotice(tokenNotice).subscribe(data => {

      this.location.go(`/fundae/chat;user_chat=${notice.conector_id}`);
        location.reload();

      setTimeout(()=>{
            this.spinnerService.hide();
          }, 500);
    });


  }

  ngOnInit() {
    this.setTitle("Avisos");
    this.spinnerService.show();

    window.scroll(0,0);

    this.account = this.trainingService.getCurrentAcount();

    this.trainingService.getNotices().subscribe(data => {

       this.notices = data;

        setTimeout(()=>{
            this.spinnerService.hide();
          }, 500);

    });

    //this.notices = this.trainingService.getCurrentNotices();

    this.trainingService.setViewedAnnouncements(1);

    this.getBgcolor();
  }

  onNext(){
    this.calculateNextView();
  }

  applyStyles() {
    //console.log("bg2 " + this.getBgcolor1());
    const styles = {'background-color' :  this.getBgcolor1(), 'position':'fixed', 'bottom':'0px','width': '100%', 'max-width':'700px', 'padding-left':'0px'};
    return styles;
  }

}
