import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent extends BaseComponent{

  leaderboard: any;

  private data_leaderboard: any = {
    leaderboard: []
  };

  ngOnInit() {
    this.getBgcolor();

    this.setTitle( "Ranking");

       this.account = this.trainingService.getCurrentAcount();

    this.spinnerService.show();

    this.trainingService.getLeaderboard().subscribe(
          data => {
            this.data_leaderboard = data;
            this.leaderboard = this.data_leaderboard.leaderboard;
            console.log(this.leaderboard);

             setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);

          });


  }

  applyStyles() {
    //console.log("bg2 " + this.getBgcolor1());
    const styles = {'background-color' :  this.getBgcolor1(), 'position':'fixed', 'bottom':'0px','width': '100%', 'max-width':'700px', 'padding-left':'0px'};
    return styles;
  }

}
