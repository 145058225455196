import { Component, OnInit } from '@angular/core';

import {BaseComponent} from '../../shared/base/base.component';

@Component({
  selector: 'app-development',
  templateUrl: './development.component.html',
  styleUrls: ['./development.component.scss']
})

export class DevelopmentComponent extends BaseComponent {
  nextActive: boolean= false;

  ngOnInit() {
     this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
    };

  }

  onReset(){
     this.nextActive = false;
    this.spinnerService.show();
    this.trainingService.resetDays().subscribe(
          data => {
              console.log(data);


               this.trainingService.getFeed().subscribe(
          data => {

 							  this.trainingService.setFeed(data);

                this.feed = this.trainingService.getCurrentFeed();



                if (this.feed) {

                this.questions = this.feed.questions;

                this.surveys = this.feed.surveys;

                this.notices = this.feed.notices;

                this.account = this.feed.account;

                this.brainstormings = this.feed.add_brainstormings;

                if (this.questions.length > 0){
                  this.nextActive = true;
                }

                if (this.surveys.length > 0){
                  this.nextActive = true;
                }

                if (this.brainstormings.length > 0){
                  this.nextActive = true;
                }

                setTimeout(() => {
                  this.spinnerService.hide();
                }, 500);

                alert("Se limpio el Feed");
                this.router.navigate(["/training/development"]);
              }

               });
            });
  }

  onMoreDays(){
     this.spinnerService.show();
      this.nextActive = false;
    this.trainingService.moreDay().subscribe(
          data => {

            this.trainingService.getFeed().subscribe(
          data => {

 							  this.trainingService.setFeed(data);

                this.feed = this.trainingService.getCurrentFeed();

                if (this.feed) {

                this.questions = this.feed.questions;

                this.surveys = this.feed.surveys;

                this.notices = this.feed.notices;

                this.account = this.feed.account;

                this.brainstormings = this.feed.add_brainstormings;

                if (this.questions.length > 0){
                  this.nextActive = true;
                }

                if (this.surveys.length > 0){
                  this.nextActive = true;
                }

                if (this.brainstormings.length > 0){
                  this.nextActive = true;
                }

                setTimeout(() => {
                  this.spinnerService.hide();
                }, 500);

                alert("Se Añadio un día más");
                this.router.navigate(["/training/development"]);
              }

               });



            });
  }


}
