import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatService  extends BaseService {

  addChat(content, userChat, groupId, totalTime){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/add_chat.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { user_chat: userChat,
          content: content,
          group_id: groupId,
        total_time: totalTime},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  getChats(userChat, groupId){
     const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/get_chats?user_chat=${userChat}&group_id=${groupId}`;

    return this.http.get(url_api);
  }

  getListChats(groupId){
     const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/get_list_chats?group_id=${groupId}`;

    return this.http.get(url_api);
  }


  getContacts(groupId){
     const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/get_contacts?group_id=${groupId}`;

    return this.http.get(url_api);
  }

}
