import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/components/shared/base/base.component';
declare var audiojs: any;
@Component({
  selector: 'app-answered',
  templateUrl: './answered.component.html',
  styleUrls: ['./answered.component.scss']
})
export class AnsweredComponent extends BaseComponent{

  nextQuestion: string;

  questionIndex: any;

  questionPercentage: any;

  checkedList = [];

  totalTime: any;

  indexQuestion: any;

  answer_correct: any;

  answerToken: any;

  points: any;

  parcial: any;

  contentHtml = "";
  contentHtmlAlt = "";

  existAudio = false;

  applyStyles() {
    const styles = {'background-color' :  this.getBgcolor1(), 'position':'fixed', 'bottom':'0px','width': '100%', 'max-width':'700px', 'padding-left':'0px'};
    return styles;
  }

  ngOnInit() {
    window.scroll(0,0);
    this.spinnerService.show();
    this.getBgcolor();

    this.totalTime = new Date();

    const questionToken = this.route.snapshot.params['token'];

    this.answerToken = this.route.snapshot.paramMap.get('answer');

    this.points = this.route.snapshot.paramMap.get('points');

    this.answer_correct = this.route.snapshot.paramMap.get('correct');

    this.parcial = this.route.snapshot.paramMap.get('parcial');

    const answerToken = this.answerToken;

    //console.log(this.answerToken);

    this.account = this.trainingService.getCurrentAcount();

    this.questions = this.trainingService.getCurrentQuestions();

    var f: number;

    this.question = this.questions.find(function(item, index) {
      f = index;
      return item.token === questionToken;
    });

    //this.answer_correct = false;

     if (this.question.question.includes("<audio")){
      this.existAudio = true;
    }

    var _this = this;

    if (this.question.type_question != 'multiple'){

       console.log(this.answer_correct);
    }else{

      console.log(this.answer_correct);

    }

    //console.log(this.answer_correct);

    this.contentHtml = this.getContent(this.question.question);
    this.contentHtmlAlt = this.getContent(this.question.question_alternative);

    this.indexQuestion = f + 1;

    setTimeout(()=>{
      this.spinnerService.hide();

       audiojs.events.ready(function() {
         var as = audiojs.createAll();
       });


    }, 500);
  }

  getContent(question){

    if (question.includes("<audio")){
      return question;
    }else{
      var q = question;
      return this.sanitizer.bypassSecurityTrustHtml(q);
    }
  }

  captureTime(){
    var timeNow: any;

    timeNow = new Date();

    var currentTime =  Math.floor((timeNow -  this.totalTime)/1000);

    var questionToken = this.question.token;

     this.trainingService.captureRead(questionToken, currentTime).subscribe(
          data => {
            console.log(currentTime);
          });

  }

  onNext(){
    window.scroll(0,0);
		this.spinnerService.show();


    var totalQuestions;
    var currentQuestion = this.trainingService.getCurrentQuestion();

    this.captureTime();

    totalQuestions = this.questions.length - 1;

    console.log("this.trainingService.isRevision() " + this.trainingService.isRevision());

    if (this.trainingService.isRevision() != "0"){

      this.questions = this.trainingService.getCurrentQuestions();

      const questionToken = currentQuestion.token;

      var f: number;

      this.question = this.questions.find(function(item, index) {
        f = index + 1;
        return item.token === questionToken;
      });

      var totalQuestions;

      totalQuestions = this.questions.length - 1;

      console.log(this.questions.length);

      if (f > totalQuestions){
        f = 0;
      }

      if (f <= totalQuestions){

        console.log("xxx");
        this.nextQuestion = this.questions[f].token;

        setTimeout(()=>{
          this.spinnerService.hide();
        }, 500);

         window.scroll(0,0);

         if (this.questions[f].type_question == "pildora"){

           this.router.navigate([`/training/question/${this.nextQuestion}/pildora`]);
         }else{
            this.router.navigate(["/training/question", this.nextQuestion]);
         }

      }else{
        console.log("ssss");
      }


    }else{
      if (currentQuestion.valoration){
       //window.location.href = "/training/valoration/" + currentQuestion.token;
        setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);
      this.router.navigate(["/training/valoration/", currentQuestion.token]);
    }else{
        setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);
      this.calculateNextView();
    }
    }




  }

}
