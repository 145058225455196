import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {FormControl} from '@angular/forms';
import {ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent  extends BaseComponent {

  public user: any;



  public users:  any = {
    fullname: "",
    token: ""
  };


   writeMsg = new FormControl('');

     public isAdmin: boolean = false;

    public myGroup = "";


   openSm(content) {
    this.modalService.open(content, { size: 'sm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
  }

  public user_chat: any;


   public chats: any;

   // This function is used in open
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

  ngOnInit() {

    window.scroll(0, 0);

    this.spinnerService.show();
    this.user_chat = 0;
    this.account = this.trainingService.getCurrentAcount();
    this.user = this.trainingService.getCurrentUserPublic();

     if ( this.authService.isAdmin() == "1"){
        this.isAdmin = true;
      }else{
        this.isAdmin = false;
      }

     this.myGroup = this.authService.getGroup();

     this.setTitle("CHAT");

    this.getBgcolor();

    this.chatService.getListChats(this.user.group_fundae_id).subscribe(data => {

      this.chats = data;


      if (this.chats.length == 0){
         this.router.navigate(["/fundae/chat/", {user_chat:0}]);
      }else{
        this.chatService.getContacts(this.user.group_fundae_id).subscribe(data => {
            this.users = data;
            setTimeout(() => {
              this.spinnerService.hide();
            }, 500);

          });
      }

      });
  }


  public modalReference: any;
  closeResult: any;

  openChat(user_id){
       //this.modalReference = this.modalService.open(content);
      window.scroll(0,0);



      this.modalService.dismissAll();

    this.router.navigate(["/fundae/chat", {user_chat: user_id}]);

  }
  backClicked(){
    this.location.back();
  }

  applyStyles() {

    const styles = {'background-color' :  this.getBgcolor1(), 'position':'fixed', 'bottom':'0px','width': '100%', 'max-width':'700px', 'padding-left':'0px'};
    return styles;
  }

}
