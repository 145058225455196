import {Component, Input, OnInit} from '@angular/core';

import {BaseComponent} from '../base/base.component';
import {NavigationEnd} from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent extends BaseComponent {
  @Input() isDesktop: boolean;
  @Input() isGrowth: boolean;
  public user: any = {
    fullname: '',
    email: '',
    description: '',
    franchise: false
  };

  public user_public: any = {
    fullname: '',
    email: '',
    description: '',
    franchise: false
  };

  public account: any = {
    fullname: '',
    email: '',
    description: '',
    franchise: false,
    bg_color:'',
    bg_color2: ''
  };


  ngOnInit() {
     this.onCheckUser();

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };





    if (this.authService.getCurrentUser()) {

      this.account = this.trainingService.getCurrentAcount();
      this.setTitle(this.account.name);
      console.log("titulo " + this.account.name);

      this.user_public = this.trainingService.getCurrentUserPublic();

    }


  }


  applyStyles() {
    //console.log("bg " + this.getBgcolor1());
    const styles = {
      'background-color': this.getBgcolor1(),
      'height': '60px'
    };
    return styles;
	}

}
