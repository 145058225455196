import {Component, OnInit, Input, HostListener} from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import {BaseComponent} from '../base/base.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent extends BaseComponent {
  @Input() isDesktop: boolean;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.eRef.nativeElement.contains(event.target)) {
      console.log("clicked inside");
    } else {
      console.log("clicked outside");
      console.log(event.target.id);

      if(event.target.id == "navbar-toggler"){

        return;
      }

      if(event.target.id == "navbar-toggler-icon"){
        return;

      }


       if ($('.navbar-toggler').length){

    if ($('#sidebar').is(":visible")){

       console.log("SM");

      var width = $('.root_container').width() / 2;

      var height = $(window).height();

      var st = $("#sidebar").attr("style");

      $("#sidebar").attr("style", $("#sidebar").attr("style") + ";width:" + width + "px;height:" + height + "px");

      $('#sidebar').animate({width:'toggle'});
    }
  }


    }
  }

  public user: any = {
    fullname: '',
    email: '',
    description: '',
    franchise: false
  };

  public user_public: any = {
    fullname: '',
    email: '',
    description: '',
    franchise: false
  };

  public account: any = {
    fullname: '',
    email: '',
    description: '',
    franchise: false,
    bg_color:'',
    bg_color2: ''
  };

  public isAdmin: boolean = false;
  public myGroup = "";

  ngOnInit() {


    var device_height = $(document).height() - 142;

    $('.menu').height(device_height);

    if (this.authService.getCurrentUser()){
      this.user = this.trainingService.getCurrentUser();
      this.user_public = this.trainingService.getCurrentUserPublic();
      this.account = this.trainingService.getCurrentAcount();

      if ( this.authService.isAdmin() == "1"){
        this.isAdmin = true;
      }else{
        this.isAdmin = false;
      }

      this.myGroup = this.authService.getGroup();

    }
    this.getComponentName();
    console.log("---");
  }

  onLogout(): void {
    this.spinnerService.show();
    this.authService.logoutUser();
    this.location.go("?logout");
      setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);
    location.reload();
  }

  applyStyles() {
    //console.log("bg2 " + this.getBgcolor1());
    const styles = {
  'background-color':  this.getBgcolor1()};

    return styles;
  }

}
