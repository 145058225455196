import { Injectable } from '@angular/core';

import { BaseService } from '../services/base.service';

import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { isNullOrUndefined } from "util";

@Injectable({
  providedIn: 'root'
})

export class TrainingService extends BaseService{

	feed: Observable<any>;

 	getFeed(){
    const url_api = `${this.getEndPoint()}/api/v1/user2/${this.getToken()}/${this.getCourseToken()}.json?_nocache=${(new Date).getTime()}&cycle=${this.getCourseCycle()}`;

    return (this.feed = this.http.get(url_api).pipe(map(data => data)));
 	}



 	getUserAvailables(){

 	  const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/get_user_availables.json?_nocache=${(new Date).getTime()}`;

    return this.http.get(url_api).pipe(map(data => data));
  }

   getContents(){
     const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/get_contents`;
    return this.http.get(url_api).pipe(map(data => data));
  }


  setRegisterDevice() {

 	   if (this.getTokenDevice()) {

 	     console.log( "TOKEN " + this.getTokenDevice());

       this._setRegisterDevice().subscribe(
         data => {
           console.log("ho");
         });
     }else{



 	       this._setRegisterDevice2().subscribe(
         data => {
           console.log("ho");
         });
     }
  }

  _setRegisterDevice2() {
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/register_device.json?_nocache=${(new Date).getTime()}`;

    var de =   this.getDevice();

    return this.http
      .post(
        url_api,
        {
          device: de,
        web: "1"},
        { headers: this.headers }
      )
      .pipe(map(data => data));

  }

  _setRegisterDevice() {
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/register_device.json?_nocache=${(new Date).getTime()}`;

    var de =  "WBASIC " + this.getDevice();

    return this.http
      .post(
        url_api,
        { device_token: this.getTokenDevice(),
          device: de},
        { headers: this.headers }
      )
      .pipe(map(data => data));

  }


  answeredQuestion(question, answer, total_time){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/answering.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { question: question, 
          answer: answer,
          total_time: total_time,
        cycle: this.getCourseCycle()},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  addValoration(question, group, rating, content){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/add_valoration.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { question: question,
          rating: rating,
          group: group,
          content: content},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  captureRead(question, total_time){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/time_read.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { question: question,
          total_time: total_time},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  answeredPildora(question, total_time){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/answering.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { question: question, 
          pildora: 1,
          total_time: total_time,
        cycle: this.getCourseCycle()},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  answeredSurveyNormal(survey, opcion){
 	  const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/answering_survey.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { survey: survey,
          opcion: opcion,
        carlos: 1},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  answeredSurveyMultiple(survey, options){
 	  const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/answering_survey_multiple.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { survey: survey,
          options: options,
        carlos: 1},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  answeredBrainstorming(brainstorming, content){
 	  const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/answering_brainstorming.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { brainstorming: brainstorming,
          content: content,
        carlos: 1},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  loadLogin() {
    const url_api = `${this.getEndPoint()}/load_auto_login?token_nocache=${(new Date).getTime()}`;

    return this.http
      .get(
        url_api
      )
      .pipe(map(data => data));

  }

  moreDay(){
 	  const url_api = `${this.getEndPoint()}/api/v1/update_days?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { token: this.getToken()},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  resetDays(){
 	   const url_api = `${this.getEndPoint()}/api/v1/clear_days?_nocache=${(new Date).getTime()}`;

 	   return this.http
      .post(
        url_api,
        { token: this.getToken()},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  getDashboard(){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/dashboard.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .get(
        url_api,
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  getConcept(question){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/get_concept.json?_nocache=${(new Date).getTime()}&question=${question}`;

    return this.http
      .get(
        url_api,
        { headers: this.headers }
      ).pipe(map(data => data));
  }

  getReload(){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/get_reload.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .get(
        url_api,
        { headers: this.headers }
      ).pipe(map(data => data));
  }


  getResults(){
    const url_api = `${this.getEndPoint()}/api/v1/user2/${this.getToken()}/${this.getCourseToken()}/results.json?_nocache=${(new Date).getTime()}&cycle=${this.getCourseCycle()}`;

    return this.http
      .get(
        url_api,
        { headers: this.headers }
      ).pipe(map(data => data));
  }

   getLeaderboard(){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/leaderboard.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .get(
        url_api,
        { headers: this.headers }
      ).pipe(map(data => data));
  }

  getNotices(){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/get_announcements.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .get(
        url_api,
        { headers: this.headers }
      ).pipe(map(data => data));
  }


  getTestNotification(){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/update_feed.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { headers: this.headers }
      ).pipe(map(data => data));
  }



  getReadNotice(tokenNotice){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/get_read_announcement.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { notice: tokenNotice},
        { headers: this.headers }
      ).pipe(map(data => data));
  }




  setFontSize(fs){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/set_font_size.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { fs: fs},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }


  showDisableQuestions(){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/show_disable_question.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { question: "ok"},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  saveConcept(questionToken, content){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/save_content.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { question: questionToken,
          content: content},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  showEnableQuestions(){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/show_enable_questions.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { question: "ok"},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  disableQuestion(question){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/disable_question.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { question: question},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  enableQuestion(question){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/enable_question.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { question: question},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  removeNode(data, id) {
      return data.filter(function(emp) {
          if (emp["token"] == id) {
              return false;
          }
          return true;
      });
  }


  removeBrainstorming(currentBrainstorming){

 	  var json = this.getCurrentFeed();
     console.log(json["add_brainstormings"].length);


    var add_brainstormings = this.removeNode(json["add_brainstormings"], currentBrainstorming.token);

    json["add_brainstormings"] = add_brainstormings;

    let feed_string = JSON.stringify(json);
    localStorage.setItem("currentFeed", feed_string);


  }


  removeSurvey(currentSurvey){

 	  var json = this.getCurrentFeed();
     console.log(json["surveys"].length);


    var surveys = this.removeNode(json["surveys"], currentSurvey.token);

    json["surveys"] = surveys;

    let feed_string = JSON.stringify(json);
    localStorage.setItem("currentFeed", feed_string);


  }

  removeQuestion(currentQuestion){

 	  var json = this.getCurrentFeed();
     console.log(json["questions"].length);


    var questions = this.removeNode(json["questions"], currentQuestion.token);

    json["questions"] = questions;

    let feed_string = JSON.stringify(json);
    localStorage.setItem("currentFeed", feed_string);


  }
  answeredMultiple(question, answers, correct, total_time){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/answering_multiple.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { question: question, 
          answers: answers,
          correct: correct,
          total_time: total_time},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

 	setFeed(feed): void {
    let feed_string = JSON.stringify(feed);

    let questions_string = JSON.stringify(feed.questions);
    let surveys_string = JSON.stringify(feed.surveys);
    let add_brainstormings_string = JSON.stringify(feed.add_brainstormings);
    let notices_string = JSON.stringify(feed.notices);
    let leaderboard_string = JSON.stringify(feed.leaderboard);
    let account_string = JSON.stringify(feed.account);
    let user_string = JSON.stringify(feed.user);

    localStorage.setItem("currentFeed", feed_string);
    localStorage.setItem("currentUserPublic", user_string);
    console.log(account_string);

    localStorage.setItem("currentAccount", account_string);
    localStorage.setItem("currentQuestions", questions_string);
    localStorage.setItem("currentSurveys", surveys_string);
    localStorage.setItem("currentAddBrainstormings", add_brainstormings_string);
    localStorage.setItem("currentNotices", notices_string);
    localStorage.setItem("currentLeaderboard", leaderboard_string);
  }

  setCurrentQuestion(question): void {
    let question_string = JSON.stringify(question);
    localStorage.setItem("currentQuestion", question_string);
  }

  setEvaluation(boolean): void {
    localStorage.setItem("isEvaluation", boolean);
  }

  setFundae(boolean): void {
    localStorage.setItem("isFundae", boolean);
  }


  setViewedBrainstorming(viewed): void {
    localStorage.setItem("viewedBrainstorming",viewed);
  }

  getViewedBrainstorming() {
    return localStorage.getItem("viewedBrainstorming");
  }

  isEvaluation() {
    return localStorage.getItem("isEvaluation");
  }

  isFundae() {
    return localStorage.getItem("isFundae");
  }



  setViewedAnnouncements(viewed): void {
    localStorage.setItem("viewedAnnouncements",viewed);
  }



  getViewedAnnouncements(){
    return localStorage.getItem("viewedAnnouncements");
  }

  setViewedObjetives(viewed): void {
    localStorage.setItem("viewedObjetives",viewed);
  }

  getViewedObjetives(){
    return localStorage.getItem("viewedObjetives");
  }


  getCurrentQuestion(){
    let question_string = localStorage.getItem("currentQuestion");
    if (!isNullOrUndefined(question_string)) {
      let question = JSON.parse(question_string);
      return question;
    } else {
      return null;
    }
  }

  setQuestionIndex(number_int): void {
    localStorage.setItem("currentQuestionIndex", number_int);
  }

  getQuestionIndex(){
    return localStorage.getItem("currentQuestionIndex");
  }

  setAnswered(string_token): void {
    localStorage.setItem("currentAnswered", string_token);
  }

  getAnswered(){
    return localStorage.getItem("currentAnswered");
  }

  setAnsweredMultiple(string_tokens): void {
    let tokens = JSON.stringify(string_tokens);

    localStorage.setItem("currentAnsweredMultiple", tokens);
  }

  getAnsweredMultiple(){
    let answers_multiple = localStorage.getItem("currentAnsweredMultiple");
    if (!isNullOrUndefined(answers_multiple)) {
      let multiples = JSON.parse(answers_multiple);
      return multiples;
    } else {
      return null;
    }
  }
}
