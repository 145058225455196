import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ForumApiService extends BaseService {

  addTopic(question, content, replyToken, totalTime){
    const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/add_topic.json?_nocache=${(new Date).getTime()}`;

    return this.http
      .post(
        url_api,
        { question: question,
          content: content,
        replyToken: replyToken,
          total_time: totalTime,
        group_id: this.getGroup()},
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  getTopics(question, replyToken){
     const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/get_topics?question=${question}&replyToken=${replyToken}&group_id=${this.getGroup()}`;
    return this.http.get(url_api);
  }

  getForums(course_id){
     const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/get_forums?course_id=${course_id}`;
    return this.http.get(url_api);
  }


  getQuestion(question){
     const url_api = `${this.getEndPoint()}/api/v1/user/${this.getToken()}/get_question?question=${question}`;

    return this.http.get(url_api);
  }





}
