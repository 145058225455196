import { Component, OnInit } from '@angular/core';

import {PlanComponent} from '../plan/plan.component';


@Component({
  selector: 'app-start-plan',
  templateUrl: './start-plan.component.html',
  styleUrls: ['./start-plan.component.scss']
})
export class StartPlanComponent extends PlanComponent {




  ngOnInit() {
     this.spinnerService.show();

  	this.getBgcolor();
    const plan_id = this.route.snapshot.params['id'];

    this.dataApiService.getPlanById(plan_id).subscribe(plan => {
      this.plan = plan;


       this.dataApiService.getObjetives(plan_id).subscribe(objetives => {

         (<any>this.objetives) = objetives;

        setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);

       });


    });

    //this.getDetails(plan_id);

    console.log(plan_id);

    //this.getObjetivesAll(plan_id);
    
  }

  applyStyles() {

    

    if (this.actionsborder == "box-shadow: none;") {
      const styles = {
        'box-shadow': 'none'
      };
      return styles;
    }
  }

  

}
