import { Component, OnInit } from '@angular/core';
import {PlanComponent} from '../plan/plan.component';
import {FormControl} from '@angular/forms';
import {BrainstormingInterface} from '../../../../models/brainstorming-interface';

@Component({
  selector: 'app-antes-valoration',
  templateUrl: './antes-valoration.component.html',
  styleUrls: ['./antes-valoration.component.scss']
})
export class AntesValorationComponent extends PlanComponent{



  brainstorming: BrainstormingInterface;


  index = 0;

  ngOnInit() {
    window.scroll(0,0);

    this.spinnerService.show();

    this.getBgcolor();


    const plan_id = this.route.snapshot.params['id'];

    this.index = this.route.snapshot.params['index'];

    this.dataApiService.getPlanById(plan_id).subscribe(plan => {
      this.plan = (<any>plan);

      this.brainstormings = (<any>this.plan).antes.brainstormings;
      this.surveys = (<any>this.plan).antes.surveys;
      this.valorations = (<any>this.plan).antes.valorations;

      this.valoration = this.valorations[this.index];

      setTimeout(()=>{
        this.spinnerService.hide();
      }, 500);
    });

  }


  selectOption2(option: string){
    console.log(this.valoration.id);
    console.log((<any>this.plan).id);
    console.log(option);
    this.spinnerService.show();
    return this.dataApiService.saveValoration((<any>this.plan).token, this.valoration.id, option).subscribe(
        data => {

          window.scroll(0,0);
          //this.getDetailValoration(this.action_id);

          if (this.valorations.length  <= (this.index + 1)){

             return this.dataApiService.setStatus((<any>this.plan).token, "10").subscribe( data => {
                window.scroll(0,0);
                this.router.navigate([`growth`]);

              },
              error => this.onIsError()
            );

          }else{

            var sum  = (parseInt(<any>this.index) + 1);

            return this.router.navigate([`growth/plan/${(<any>this.plan).token}/value/${sum}/valorations`]);
          }



        },
        error => this.onIsError()
      );

  }



}
