import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { PlanInterface } from '../models/plan-interface';
import { UserInterface } from '../models/user-interface';
import { ObjetiveInterface } from '../models/objetive-interface';
import { MarketingActionInterface } from '../models/marketing-action-interface';

import { isNullOrUndefined } from "util";
import {AntesInterface} from '../models/antes-interface';

@Injectable({
  providedIn: 'root'
})

export class DataApiService {

  constructor(private http: HttpClient) { }

  plans: Observable<any>;
	 plan: PlanInterface = {
    token: '',
    put_into_practice: '',
    id: '',
    action_title: '',
    description: '',
    is_required: true,
    start_date: '',
    finalized_date: '',
    status: "1",
    class_bg: 'bg-warning',
     valoration: false,
     antes:[]
  };
  objetive: Observable<any>;
  user: Observable<any>;
  valoration: Observable<any>;

  focus: Observable<any>;
  objetives:  Observable<any>;
  marketing_actions:  Observable<any>;
  metric_pis:  Observable<any>;
  
	public selectedPlan: PlanInterface = {
    token: null,
    id: null,
    action_title: '',
    description: '',
    valoration: false,
    antes: []

  };

    getFontSize(){

	   return localStorage.getItem("font_size");

  }

	isRevision() {
    return localStorage.getItem("currentRevision");
  }

  getEndPoint(){
    return "https://old.wiserteams.com";
    //return "http://localhost:3000";
  }


  getAllObjetives(){
    
    const url_api = `${this.getEndPoint()}/api/v1/all_action_plans_franchise?accessToken=${this.getToken()}`;

    return (this.marketing_actions = this.http.get(url_api));
  }

  getObjetives(plan){
    console.log(plan);
    
     const url_api = `${this.getEndPoint()}/api/v1/action_plans_franchise?accessToken=${this.getToken()}&plan=${plan}`;
    return (this.objetives = this.http.get(url_api));
  }

  getFocus(){
     const url_api = `${this.getEndPoint()}/api/v1/get_focus?accessToken=${this.getToken()}`;
    return (this.focus = this.http.get(url_api));
  }

  reset(){
     const url_api = `${this.getEndPoint()}/api/v1/reset_franchise?accessToken=${this.getToken()}`;
    return this.http.get(url_api);
  }


  getValoration(id: string){
     const url_api = `${this.getEndPoint()}/api/v1/plan_valoration/${id}?accessToken=${this.getToken()}`;
    return (this.valoration = this.http.get(url_api));
  }

  saveValoration(id: string, valoration_id: string, option: string){
     const url_api = `${this.getEndPoint()}/api/v1/save_valoration/${id}?valoration_id=${valoration_id}&option_text=${option}&accessToken=${this.getToken()}`;
    return (this.valoration = this.http.get(url_api));
  }

  saveComment(id: string, comment: string){
    const url_api = `${this.getEndPoint()}/api/v1/save_plan_franchise_user_comments/${id}?comment=${comment}&accessToken=${this.getToken()}`;
    return (this.valoration = this.http.get(url_api));
  }

  savePKI(id: string, value: string){
     const url_api = `${this.getEndPoint()}/api/v1/save_kpi/${id}?value=${value}&accessToken=${this.getToken()}`;
    return (this.valoration = this.http.get(url_api));
  }




  getMetricPis(){
     const url_api = `${this.getEndPoint()}/api/v1/get_metric_pis?accessToken=${this.getToken()}`;
    return (this.metric_pis = this.http.get(url_api));
  }


  getMetricPis3(){
     const url_api = `${this.getEndPoint()}/api/v1/get_metric_pis2?order=1&accessToken=${this.getToken()}`;
    return (this.metric_pis = this.http.get(url_api));
  }

  getMetricPis2(){
     const url_api = `${this.getEndPoint()}/api/v1/get_metric_pis2?accessToken=${this.getToken()}`;
    return (this.metric_pis = this.http.get(url_api));
  }

  verifyToken(token: string) {
    const url_api = `${this.getEndPoint()}/api/v1/verify_token/${token}`;
    return (this.user = this.http.get<UserInterface>(url_api).pipe(map(data => data)));
  }


  setUser(user: UserInterface): void {
    let user_string = JSON.stringify(user);
    localStorage.setItem("currentUser", user_string);
  }

  setFranchise(franchise: string): void {
    
    localStorage.setItem("currentFranchise", franchise);
  }


  setToken(token: string): void {
    localStorage.setItem("accessToken", token);
  }


  setBgcolor(bg1: string, bg2: string): void {
    localStorage.setItem("bgcolor1", bg1);
    localStorage.setItem("bgcolor2", bg2);
  }


  getCurrentUser(): UserInterface {
      let user_string = localStorage.getItem("currentUser");
      if (!isNullOrUndefined(user_string)) {
        let user: UserInterface = JSON.parse(user_string);
        return user;
      } else {
        return null;
      }
  }

  getBgcolor1(){
    return localStorage.getItem("bgcolor1");
  }

  getBgcolor2(){
    return localStorage.getItem("bgcolor2");
  }


  getToken(){
    return localStorage.getItem("accessToken");
  }

  getFranchise(){
    return localStorage.getItem("currentFranchise");
  }

  setStatus(id: string, status: string) {
    const url_api = `${this.getEndPoint()}/api/v1/status_plan/${id}?status=${status}&accessToken=${this.getToken()}`;
    return ((<any>this.plan) = this.http.get(url_api));
  }

  setComplete(id: string) {
    const url_api = `${this.getEndPoint()}/api/v1/set_complete/${id}?accessToken=${this.getToken()}`;
    return (this.objetive = this.http.get(url_api));
  }

	getPlanById(id: string): Observable<any> {
    const url_api = `${this.getEndPoint()}/api/v1/plan/${id}?accessToken=${this.getToken()}`;
    return ((<any>this.plan) = this.http.get(url_api));
	}

  getObjetiveById(id: string) {
    const url_api = `${this.getEndPoint()}/api/v1/objetive/${id}?accessToken=${this.getToken()}`;
    return (this.objetive = this.http.get(url_api));
  }

  getPlanUserById(id: string) {
    const url_api = `${this.getEndPoint()}/api/v1/plan_franchise_user/${id}?accessToken=${this.getToken()}`;
    return ((<any>this.plan) = this.http.get(url_api));
  }

	getAllPlans(status: string, before: string, is_required: string) {

    const url_api = `${this.getEndPoint()}/api/v1/get_all_plans?status=${status}&before=${before}&is_required=${is_required}&accessToken=${this.getToken()}`;
    //return this.http.get(url_api).pipe(map(data => data));

    return (this.plans = this.http.get(url_api));

	}

	getValorationPlans() {

    const url_api = `${this.getEndPoint()}/api/v1/get_valoration_plans?accessToken=${this.getToken()}`;
    
    return (this.plans = this.http.get(url_api));

	}



  getFilterPlans(focus: string) {

    const url_api = `${this.getEndPoint()}/api/v1/get_filter_plans?ids=${focus}&accessToken=${this.getToken()}`;
    //return this.http.get(url_api).pipe(map(data => data));

    return (this.plans = this.http.get(url_api));

  }


}
