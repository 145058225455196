import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import {PlanComponent} from '../plan/plan.component';

import { PlanInterface } from '../../../../models/plan-interface';
import { DataApiService } from '../../../../services/data-api.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-finished-plan',
  templateUrl: './finished-plan.component.html',
  styleUrls: ['./finished-plan.component.scss']
})

export class FinishedPlanComponent extends PlanComponent {

	speechText = new FormControl('');

  openO = false;


  
  ngOnInit() {
    this.spinnerService.show();

    this.getBgcolor();
  	const plan_id = this.route.snapshot.params['id'];

    window.writeMessage = this.writeMessage.bind(this);

    this.speechText.setValue("Toca el icono para iniciar...");

    //this.getDetailsUser(plan_id);

     this.dataApiService.getPlanUserById(plan_id).subscribe(plan_user => {

       this.plan_user = plan_user;


       this.dataApiService.getPlanById(plan_id).subscribe(plan => {
          this.plan = plan;

          //this.getDetails(plan_id);

           setTimeout(()=>{
            this.spinnerService.hide();
          }, 500);

       });


     });




  }


  openMic(){

    if (this.openO){
      this.openO = false
      window.location.href = "close://microphone";
    }else{
      this.openO = true
      window.location.href = "open://microphone";
    }
  }

  placeHolderComment(){

    if (this.speechText.value == "Toca el icono para iniciar..."){
      this.speechText.setValue("");
    }else{
       console.log("sss2");
    }
  }

  autogrow() {
    let textArea = document.getElementById("comments")
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  writeMessage(message){
    this.speechText.setValue(message);
    this.autogrow();
  }

}
