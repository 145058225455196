import { Component, OnInit } from '@angular/core';
import {PlanComponent} from '../plan/plan.component';

@Component({
  selector: 'app-objetive',
  templateUrl: './objetive.component.html',
  styleUrls: ['./objetive.component.scss']
})
export class ObjetiveComponent  extends PlanComponent {

  ngOnInit() {
    window.scroll(0,0);
    this.getBgcolor();
    
  	 const objetive_id = this.route.snapshot.params['id'];
  	 

  	 if (this.route.snapshot.params['plan']){
  	 	const plan_id = this.route.snapshot.params['plan'];
  	 	this.getDetails(plan_id);
  	 }

    this.getObjetive(objetive_id);
  }


 
  getBackLink(){
    window.scroll(0,0);
  	if ((<any>this.plan).token != ""){

  		return this.router.navigate([`./growth/start-plan/${(<any>this.plan).token}`]);
  	}else{
  		return this.router.navigate([`./growth/objetives`]);
  	}


  	
  }

}
