import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/components/shared/base/base.component';

import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-factory',
  templateUrl: './factory.component.html',
  styleUrls: ['./factory.component.scss']
})
export class FactoryComponent extends BaseComponent{

  name: any;
  device: any;
  device_token: any;

  ngOnInit() {
    window.scroll(0, 0);

    this.account = this.trainingService.getCurrentAcount();
    this.setTitle(this.account.name);

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.device = this.authService.getDevice();
    this.device_token = this.authService.getTokenDevice();
    if (this.authService.getTokenDevice()) {

    }else{
      var deviceInfo = this.deviceService.os + " " + this.deviceService.userAgent;

       if (this.deviceService.isMobile()){
         deviceInfo += " MOVIL";
       }

       if (this.deviceService.isTablet()){
          deviceInfo += " TABLETA";
       }

       if (this.deviceService.isDesktop()){
         deviceInfo += " ESCRITORIO";
       }

      this.authService.setDevice(deviceInfo);
    }
    this.trainingService.setRegisterDevice();

    console.log( "TOKEN " + this.device_token);

    this.calculateNextView();
  }




}
