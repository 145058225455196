import { Component, OnInit } from '@angular/core';

import {PlanComponent} from '../plan/plan.component';

@Component({
  selector: 'app-details-plan',
  templateUrl: './details-plan.component.html',
  styleUrls: ['./details-plan.component.scss']
})

export class DetailsPlanComponent extends PlanComponent {


  ngOnInit() {
    window.scroll(0,0);
    this.spinnerService.show();
  	this.getBgcolor();
    const plan_id = this.route.snapshot.params['id'];

    this.dataApiService.getPlanById(plan_id).subscribe(plan => {
      this.plan = plan;

        setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);
    });


  }


  


}
