import { Component, OnInit } from '@angular/core';

import {PlanComponent} from '../plan/plan.component';


@Component({
  selector: 'app-potencial-plans',
  templateUrl: './potencial-plans.component.html',
  styleUrls: ['./potencial-plans.component.scss']
})
export class PotencialPlansComponent extends PlanComponent {



 ngOnInit() {
 	this.getBgcolor();
  	
  	this.getListPlansFilter(this.route.snapshot.paramMap.get("id"));
	  

  }


}
