import { Component, OnInit } from '@angular/core';
import { MetricPiInterface } from '../../../models/metric-pi-interface';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { isError } from 'util';
import { DataApiService } from '../../../services/data-api.service';


import {PlanComponent} from '../plans/plan/plan.component';

@Component({
  selector: 'app-bench',
  templateUrl: './bench.component.html',
  styleUrls: ['./bench.component.scss']
})
export class BenchComponent extends PlanComponent {

	metric_pis: MetricPiInterface;

  ngOnInit() {
    this.setTitle("Benchmarking");
    this.getBgcolor();
  	this.getMetricPis2();
  }


  getMetricPis2(){
    return this.dataApiService.getMetricPis2().subscribe(metric_pis => (this.metric_pis = metric_pis));
  }



}
