import { Component, OnInit } from '@angular/core';

import {formatDate} from '@angular/common';
import { DataApiService } from '../../../../services/data-api.service';
import { PlanInterface } from '../../../../models/plan-interface';
import { FocuInterface } from '../../../../models/focu-interface';
import { PlanUserInterface } from '../../../../models/plan-user-interface';
import { ValorationInterface } from '../../../../models/valoration-interface';
import { ObjetiveInterface } from '../../../../models/objetive-interface';
import { MarketingActionInterface } from '../../../../models/marketing-action-interface';
import {Title} from '@angular/platform-browser';

import { NgForm } from '@angular/forms';

import { ActivatedRoute, Params, Router } from '@angular/router';
import { isError } from 'util';

import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import {NgxSpinnerService} from 'ngx-spinner';

import {BrainstormingInterface} from '../../../../models/brainstorming-interface';
import {any} from 'codelyzer/util/function';
import {Observable} from 'rxjs';

import {TrainingService} from '../../../../services/training.service';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})

export class PlanComponent implements OnInit {

   valorations:  Array<any>;
  surveys:  Array<any>;
  brainstormings: Array<BrainstormingInterface>;

  public brainstorming: BrainstormingInterface;
  

constructor(public trainingService: TrainingService, private titleService : Title , public dataApiService : DataApiService, public router : Router, public confirmationDialogService : ConfirmationDialogService, public route : ActivatedRoute, public spinnerService: NgxSpinnerService) {}

  ngOnInit() {
    this.titleService.setTitle("Planes");
  }


  public getTitle() {
    return this
      .titleService
      .getTitle();
  }

  public setTitle(newTitle : string) {
    this
      .titleService
      .setTitle(newTitle);
  }

  bgcolor10 = '';
  bgcolor20 = '';
  bgcolor30 = '';
  bgdasboardcolor = '';
  dasboardcolor = '';
  color10 = '';

  checkedList = [];

  actionsbg = '';

  actionsborder = ''; 


  getBgcolor1(){
    return "#" + this.dataApiService.getBgcolor1().replace("%23", "");
  }

  getBgcolor(){

    this.bgcolor10 = "#" + this.dataApiService.getBgcolor1().replace("%23","");


    if (this.dataApiService.getBgcolor2().replace("%23","") == "ffffff"){
      this.actionsbg = "#f2f2f2";
      this.actionsborder = "box-shadow: none;"
    }else{
      this.actionsbg = "#ffffff";
    }

    if (this.dataApiService.getBgcolor2().replace("%23","") == "ffffff"){
      this.bgcolor20 = "rgba(0, 0, 0, 0.05)";
    }else{

      this.bgcolor20 = this.getGenColor("#" + this.dataApiService.getBgcolor2().replace("%23",""), 20);
    }


     this.bgcolor30 = "#" + this.dataApiService.getBgcolor2().replace("%23","");

    this.color10 =  this.getContrastYIQ(this.bgcolor10);
    
  }

  getContrastYIQ(hexcolor){
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
  }


  getGenColor(col, amt) {
  
    var usePound = false;
  
    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }
 
    var num = parseInt(col,16);
 
    var r = (num >> 16) + amt;
 
    if (r > 255) r = 255;
    else if  (r < 0) r = 0;
 
    var b = ((num >> 8) & 0x00FF) + amt;
 
    if (b > 255) b = 255;
    else if  (b < 0) b = 0;
 
    var g = (num & 0x0000FF) + amt;
 
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
 
    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
  
}

  public isError = false;

  plansPast: any;

  plansFuture: any;

  plansStarted: any;

  plansOptional: any;

  plansFinished: any;
  plansValoration: any;
  valoration: any;

  focus: any;
 	objetives: PlanInterface;

  marketing_actions:  MarketingActionInterface;

  now_date: Date = new Date();

  objetive: ObjetiveInterface = {
    token: '',
    details: '',
    plan: ''
  };

  plan: PlanInterface = {
    token: '',
    put_into_practice: '',
    id: '',
    action_title: '',
    description: '',
    is_required: true,
    start_date: '',
    finalized_date: '',
    status: "1",
    class_bg: 'bg-warning',
     valoration: false,
     antes:[]
  };

  plan_user: PlanUserInterface = {
  
    start_date: '',
    finalized_date: ''
  };

  action_id: '';
  valoration_id: '';

 metric_pis: any;


   getMetricPis2(){
    return this.dataApiService.getMetricPis3().subscribe(metric_pis => (this.metric_pis = metric_pis));
  }

  getDetailValoration(id: string){
    this.spinnerService.show();
    return this.dataApiService.getValoration(id).subscribe(valoration => {
      
      this.valoration = valoration;
      this.spinnerService.hide();
      if (this.valoration.val_id == "0"){
        window.scroll(0,0);
         this.router.navigate([`/growth`]);
      }

    },
        error => this.onIsError()
    );
  }

  daysAgo(number: string){
    return this.now_date.setDate(this.now_date.getDate()-parseInt(number));
  }

  onStart(plan: PlanInterface) {
    return this.dataApiService.setStatus(plan.token, "2").subscribe( data => {
          window.scroll(0,0);
          this.router.navigate([`growth/start-plan/${plan.token}`]);
          
        },
        error => this.onIsError()
      );

  }

  onValoration(plan: PlanInterface){
    return this.router.navigate([`growth/plan/${plan.token}/value`]);
  }


  addComment(plan: PlanInterface, comment:string) {
    console.log(comment);
    this.spinnerService.show();

     return this.dataApiService.saveComment(plan.token, comment).subscribe(
        data => {


          this.spinnerService.hide();

          if (plan.valorations.length > 0){
            console.log("valorations");
            window.scroll(0,0);
            this.router.navigate([`growth/valoration/${plan.token}`], { queryParams: { 'refresh': 1 } });

          }else{
            console.log("sin valorations");
            this.router.navigate([`growth/`], { queryParams: { 'refresh': 1 } });
          }

        },
        error => this.onIsError()
      );


  }

  selectOption(option: string){
    console.log(this.valoration.val_id);
    console.log(this.action_id);
    console.log(option);
    this.spinnerService.show();
    return this.dataApiService.saveValoration(this.action_id, this.valoration.val_id, option).subscribe(
        data => {
          console.log("REDIRECT? " + this.action_id);
          window.scroll(0,0);
          //this.getDetailValoration(this.action_id);


          this.dataApiService.getValoration(this.action_id).subscribe(valoration => {

            this.valoration = valoration;
            this.spinnerService.hide();
            if (this.valoration.val_id == "0"){
               this.router.navigate([`/growth`]);
            }else{
               this.router.navigate([`/growth/valoration/${this.action_id}`], { queryParams: { 'refresh': (new Date).getTime() } });
                setTimeout(()=>{
            this.spinnerService.hide();
          }, 500);
                
            }

          },
              error => this.onIsError()
          );






        },
        error => this.onIsError()
      );

  }


  getAllObjetives(){
    return this.dataApiService.getAllObjetives().subscribe(marketing_actions => (this.marketing_actions = marketing_actions));
  }

  getObjetivesAll(plan){

      
  	return this.dataApiService.getObjetives((<any>plan)).subscribe(objetives => ((<any>this.objetives) = objetives));
  }

  getFocusAll(){
    return this.dataApiService.getFocus().subscribe(focus => (this.focus = focus));
  }

  onFinish(plan: PlanInterface) {
    return this.dataApiService.setStatus(plan.token, "3").subscribe(
        data => {
          window.scroll(0,0);
          this.router.navigate([`./growth/finished-plan/${plan.token}`]);
        },
        error => this.onIsError()
      );
  }

  onComplete(plan: PlanInterface, objetive: ObjetiveInterface) {
    return this.dataApiService.setComplete(objetive.token).subscribe(
        data => {
          window.scroll(0,0);
          if (plan.token != ""){

            this.router.navigate([`./growth/start-plan/${plan.token}`]);
          }else{
            this.router.navigate([`./growth/objetives`]);
          }
        },
        error => this.onIsError()
      );
  }


  onDiscard(plan: PlanInterface) {
    return this.dataApiService.setStatus(plan.token, "4").subscribe(
        data => {
          window.scroll(0,0);
          this.router.navigate(['/growth']);
        },
        error => this.onIsError()
      );
  }

  getStatus(status: string){
    return parseInt(status);
  }

  getDetails(token: string) {

    this.dataApiService.getPlanById(token).subscribe(plan => (this.plan = plan));
  }

  getObjetive(token: string) {

    this.dataApiService.getObjetiveById(token).subscribe(objetive => (this.objetive = objetive));
  }


 


  getDetailsUser(token: string) {

    this.dataApiService.getPlanUserById(token).subscribe(plan_user => (this.plan_user = plan_user));
  }

  onIsError(): void {
      this.isError = true;
      setTimeout(() => {
        this.isError = false;
      }, 4000);
  }

  getListPlansPast(): void{

     this.spinnerService.show();

    this.dataApiService
    .getAllPlans("1","1","1")
    .subscribe(plans => {
      this.plansPast = plans;

      setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);

    });


  }
  
  getListPlansFuture(): void{
     this.spinnerService.show();
    this.dataApiService
    .getAllPlans("1","2","1")
    .subscribe((plans) => {this.plansFuture = plans;

       setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);
    });
  }

  getListPlansPending(): void{
     this.spinnerService.show();
    this.dataApiService
    .getAllPlans("4","0","1")
    .subscribe((plans) => {this.plansPast = plans;

        setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);

    });
  }

  
  getListPlansStarted(): void{

      this.spinnerService.show();

    this.dataApiService
    .getAllPlans("2","0","1")
    .subscribe((plans: PlanInterface) => {
      this.plansStarted = plans;

      if (this.plansStarted){


        if ((<any>this.plansStarted).length > 0){
          this.getListPlansPast();
          this.getListPlansFuture();
        }else{
          this.getListPlansPending();
          this.plansFuture = [];
          
        }
      }



      
    });
  }

  getFinishedPlans(): void{
        this.spinnerService.show();
    this.dataApiService
    .getAllPlans("3","0","0")
    .subscribe((plans) => {
      this.plansFinished = plans;
         setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);

    });
  }

  getListPlansOptional(): void{
     this.spinnerService.show();
    this.dataApiService
    .getAllPlans("1","0","0")
    .subscribe(plans => {
      this.plansOptional = plans;
      console.log(this.plansOptional);
      setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);
    });
  }

 

  checkCheckBoxvalue(event, id){
    console.log(event)
    console.log(event.currentTarget.checked)


      if(event.target.checked) {
         this.checkedList.push(id);
       } else {
       for(var i=0 ; i < this.focus.length; i++) {
         if(this.checkedList[i] == id) {
           this.checkedList.splice(i,1);
        }
      }
    }
    console.log(this.checkedList);

  }

  getSearch(){

    if (this.checkedList.length > 0){

      const queryParams = { ids: this.checkedList.join() };
      window.scroll(0,0);
      this.router.navigate([`./growth/filter-plans-search`], { queryParams: queryParams });

    

    }else{
      alert("Selecione una metrica");
    }

  }

  getListPlansFilter(focus): void{

   
    this.dataApiService
    .getFilterPlans(focus)
    .subscribe(plans => {
      this.plansOptional = plans;
      console.log(this.plansOptional);
    });
  }


  openPlan(plan: PlanInterface): void {
    this.dataApiService.selectedPlan = Object.assign({}, plan);
    window.scroll(0,0);
    this.router.navigate([`growth/plan/${plan.token}`]);
  }

   openObjetive(plan: PlanInterface, objetive: ObjetiveInterface): void {
    window.scroll(0,0);
    this.router.navigate([`growth/start-plan/${plan.token}/${objetive.token}`]);
  }



  openObjetiveSingle(objetive: ObjetiveInterface): void {
    window.scroll(0,0);
    this.router.navigate([`growth/start-objetive/${objetive.token}`]);
  }


  openStartPlan(plan: PlanInterface): void {
     window.scroll(0,0);
    this.dataApiService.selectedPlan = Object.assign({}, plan);

    this.router.navigate([`growth/start-plan/${plan.token}`]);
  }

  openConfirmationDialog(plan: PlanInterface) {
    this.confirmationDialogService.confirm('Necesita confirmación', '¿Estas realmente seguro de descartar este plan de acción?', 'OK', 'Cancelar')
    .then((confirmed) => {
      if (confirmed){
       this.onDiscard(plan);
      }

    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

}
