import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../shared/base/base.component';

@Component({
  selector: 'app-auto-login-preview',
  templateUrl: './auto-login-preview.component.html',
  styleUrls: ['./auto-login-preview.component.scss']
})
export class AutoLoginPreviewComponent extends BaseComponent {

  carlos: any;


  ngOnInit() {
    this.authService.logoutUser();

    this.authService.setIsRevision("0");
    this.authService.setIsAdmin("0");

    this.spinnerService.show();

    const user_token = this.route.snapshot.params['token'];

    this.authService.verifyUserPreview(user_token).subscribe(
      data => {

        this.authService.setUser(data);
        this.authService.setIsRevision("1");

        this.authService.setToken(user_token);

        this.authService.setBgcolor(data.account.bg_color.replace('#', ''), data.account.bg_color2.replace('#', ''));

        console.log("..... --- ");

        this.trainingService.getFeed().subscribe(
          data => {
            this.trainingService.setFeed(data);

             this.router.navigate(['/']).then(() => {
                window.location.reload();
             });
            //location.reload();
            setTimeout(() => {
              this.spinnerService.hide();
            }, 1000);
          });
      });

    /*this.authService.verifyUser(user_token).subscribe(
       data => {
           console.log(data);
       //     this.authService.setUser(data);
       //
       //     const token = data.token;
       //     this.authService.setToken(token);
       //
       //     this.authService.setBgcolor(data.account.bg_color.replace("#",""), data.account.bg_color2.replace("#",""));
       //
       //      this.trainingService.getFeed().subscribe(
       // data => {
       //
       //         this.trainingService.setFeed(data);
       //         this.location.go(`/fundae/forum/${question_token}`);
       //         location.reload();
       //
       //         setTimeout(() => {
       //           this.spinnerService.hide();
       //         }, 1000);
       //     });


         });*/

  }


}
