import { Component, OnInit } from '@angular/core';


import { BaseComponent } from 'src/app/components/shared/base/base.component';


import { isError } from 'util';
import { NgForm } from '@angular/forms/src/directives/ng_form';

import { LoginInterface } from 'src/app/models/login-interface';
import {ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent extends BaseComponent {

  public login: LoginInterface = {
    token: '',
    e: '',
    p: '',
    n: '',
    m: false,
    fullname: ''
  };

  public isError = false;
  device: any;
  code: any;
  token_push_notification: any;
  is_franally: any;

  isFranally: Boolean = false;



  public modalReference: any;
  closeResult: any;

  message_terms: any;

  // This function is used in open
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }





  openSm(content) {
    this.modalService.open(content).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
  }
  ngOnInit() {

    this.code = this.route.snapshot.queryParamMap.get("code");

    if (this.code && this.getIsReload( ) == ""){
      this.authService.logoutUser();
      this.setIsReload("si");
      this.location.go("/login?code="+this.code);
      location.reload();


    }



    this.spinnerService.show();

    this.authService.setIsAdmin("0");
    this.authService.setIsRevision("0");
    this.trainingService.setFontSizePx(16);

    this.device = this.route.snapshot.queryParamMap.get("device");

    this.token_push_notification = this.route.snapshot.queryParamMap.get("dv");
    this.is_franally = this.route.snapshot.queryParamMap.get("franally");

    if (this.is_franally == "1"){
      this.authService.setFranally("1");
    }else{
       this.authService.setFranally("0");
    }

    this.login.e = this.getEmail();
    this.login.n = this.getName();
    this.login.p = this.code;

    if (this.device){
      this.authService.setDevice(this.device);
    }else{

       var deviceInfo = this.deviceService.os + " " + this.deviceService.userAgent;

       if (this.deviceService.isMobile()){
         deviceInfo += " MOVIL";
       }

       if (this.deviceService.isTablet()){
          deviceInfo += " TABLETA";
       }

       if (this.deviceService.isDesktop()){
         deviceInfo += " ESCRITORIO";
       }

      this.authService.setDevice(deviceInfo);
    }

    if (this.token_push_notification){
      this.authService.setTokenDevice(this.token_push_notification);
    }

    if (this.authService.getCurrentUser()){
       this.router.navigate(['/']);
    }

    setTimeout(() => {
        this.spinnerService.hide();
      }, 500);
  }

  getEmail(){
    return localStorage.getItem("currentEmail");
  }


  getIsReload(){
    return localStorage.getItem("currentReloaded");
  }

  setIsReload(value): void {

    localStorage.setItem("currentReloaded", value);
  }

  getName(){
    return localStorage.getItem("currentName");
  }
  setEmail(email): void {

    localStorage.setItem("currentEmail", email);
  }


  setName(name): void {

    localStorage.setItem("currentName", name);
  }


  onLogin(form: NgForm) {
    if (form.valid) {
      this.spinnerService.show();

      var m = "";

       if (!this.login.m){
         setTimeout(()=>{
          this.spinnerService.hide();
        }, 500);
        return alert("Para acceder al contenido, tienes que aceptar la politica de privacidad");
      }else if(!this.login.p){
         setTimeout(()=>{
          this.spinnerService.hide();
        }, 500);
        return alert("Para acceder al contenido, tienes que añadir el codigo");
      }else{

        if (this.authService.getTokenDevice()) {

        }else{
          m = "si";
        }
        this.setEmail(this.login.e);

        this.setName(this.login.n);

        return this.authService
          .loginuser(this.login.e, this.login.p, this.login.n)
          .subscribe(
          data => {

            if (data.success == "0"){
              alert(data.message);

               setTimeout(()=>{
                  this.spinnerService.hide();
                }, 500);

            }else{
              this.setIsReload("");
              this.authService.setUser(data);


              const token = data.token;
              const course_token = data.course_token;

              const course_cycle = data.course_cycle;

              this.authService.setToken(token);

              this.authService.setCourseToken(course_token);

              this.authService.setCourseCycle(course_cycle);



              this.authService.setBgcolor(data.account.bg_color.replace("#",""), data.account.bg_color2.replace("#",""));


               this.trainingService.setFontSizePx(data.account.font_size);


               this.trainingService.getFeed().subscribe(
          data => {
            console.log(data);
            this.trainingService.setFeed(data);

             this.location.go(`/?successful=${(new Date).getTime()}`);
              location.reload();
            setTimeout(() => {
              //window.location.href = `/?successful=${(new Date).getTime()}`;



              this.spinnerService.hide();
            }, 1000);
          });

              //this.location.go("/?successful_start");
              //location.reload();



            }

            this.isError = false;
          },
          error => this.onIsError()
          );
      }

    } else {
      this.onIsError();
    }
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 4000);

     setTimeout(()=>{
          this.spinnerService.hide();
        }, 500);

  }

}
