import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {FormControl} from '@angular/forms';
import {AddBoxCommentComponent} from '../add-box-comment/add-box-comment.component';

@Component({
  selector: 'app-add-forum',
  templateUrl: './add-forum.component.html',
  styleUrls: ['./add-forum.component.scss']
})
export class AddForumComponent extends BaseComponent {

  r1 = new FormControl('');

  speechText = new FormControl('');

  isDevice = false;

  openO = false;

  public_user: any;

  comentarios: any;

  showVar: boolean = false;

  replyToken: any;

  @ViewChild("target2") MyProp: ElementRef;


 q: any = {
    token: '',
    question: '',
    course_id: '',
    question_alternative: '',
    comentarios: []
  };


 questionToken: any;

  hideme: any = {};

  totalTime: any;


   public isAdmin: boolean = false;

    public myGroup = "";

  toggleChild(){
    this.MyProp.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    this.showVar = !this.showVar;
    Object.keys(this.hideme).forEach(h => {
      this.hideme[h] = false;
    });
  }

  onRefresh(event){
    this.questionToken = this.route.snapshot.params['token'];
    this.forumApiService.getTopics(this.questionToken, this.replyToken).subscribe(data => {

          this.q = data;
          this.comentarios = this.q.comentarios;


      });
  }

  onShow(item) {
    Object.keys(this.hideme).forEach(h => {
      this.hideme[h] = false;
    });
    this.hideme[item.id] = true;
  }

  ngOnInit(){

    this.spinnerService.show();

     window.writeMessage = this.writeMessage.bind(this);

    window.scroll(0,0);

    this.totalTime = new Date();

    if ( this.authService.isAdmin() == "1"){
        this.isAdmin = true;
      }else{
        this.isAdmin = false;
      }

    this.myGroup = this.authService.getGroup();

    this.account = this.trainingService.getCurrentAcount();

    this.public_user = this.trainingService.getCurrentUser();

    this.setTitle(this.account.name);

    this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
    };


    if (this.trainingService.getDevice()){
      this.isDevice = true;
    }

     window.writeMessage = this.writeMessage.bind(this);

    this.speechText.setValue("Toca el icono para iniciar...");

    this.questionToken = this.route.snapshot.params['token'];

    //this.questions = this.trainingService.getCurrentQuestions();
    this.replyToken = this.route.snapshot.params['replyToken'];
    var f: number;


     this.getBgcolor();

      console.log(" " + this.questionToken);
      this.forumApiService.getTopics(this.questionToken, this.replyToken).subscribe(data => {

          this.q = data;


          console.log(" " + this.q);

          this.comentarios = this.q.comentarios;




          setTimeout(()=>{
            this.spinnerService.hide();
          }, 500);
      });

  }

   ngAfterViewChecked(){
    console.log("adios");
// const targetCard = this.itemCards.toArray()[4];
      //targetCard.scrollIntoView();

      console.log("questionToken " + this.replyToken);
      let el2 = document.getElementById(this.replyToken);

      if(el2){
            el2.scrollIntoView();

      }

  }

  ngAfterViewInit(){
    console.log("aaa");
  }

  openMic(){
    if (this.openO){
      this.openO = false;
      window.location.href = "close://microphone";
    }else{
      this.openO = true;
      window.location.href = "open://microphone";
    }
  }

  placeHolderComment(){
    if (this.speechText.value == "Toca el icono para iniciar..."){
      this.speechText.setValue("");
    }else{
       console.log("sss2");
    }
  }

  autogrow() {
    let textArea = document.getElementById("comments")
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  writeMessage(message){
    console.log("add2");
    let textArea = <HTMLInputElement>document.getElementById('comments');
    textArea.value = message;
    this.autogrow();
  }

  backClicked(){
    this.location.back();
  }

  applyStyles() {
    //console.log("bg2 " + this.getBgcolor1());
    const styles = {'background-color' :  this.getBgcolor1(), 'position':'fixed', 'bottom':'0px','width': '100%', 'max-width':'700px', 'padding-left':'0px'};
    return styles;
  }
}
