import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-add-box-comment',
  templateUrl: './add-box-comment.component.html',
  styleUrls: ['./add-box-comment.component.scss']
})
export class AddBoxCommentComponent extends BaseComponent implements OnChanges {

  @ViewChild('commentsInput') commentsInput;
  r1 = new FormControl('');
  speechText = new FormControl('');

  isDevice = false;
  openO = false;

  @Input() showMePartially: boolean;
  @Input() questionToken: string;

  @Input() replyToken: string;

  @Output() refresh = new EventEmitter();

  public comentarios: any;

  public q: any = {
    token: '',
    question: '',
    question_alternative: '',
    comentarios: []
  };

  isDesktopDevice: Boolean = false;
   totalTime: any;

  ngOnInit() {
    window.writeMessage = this.writeMessage.bind(this);

    this.isDesktopDevice = this.deviceService.isDesktop();

    this.speechText.setValue('Toca el icono para iniciar...');

    this.totalTime = new Date();

    this.getBgcolor();

    if (this.trainingService.getDevice()) {
      this.isDevice = true;
    }

  }

  ngOnChanges() {

    if (this.showMePartially) {
      setTimeout(() => {
        this.speechText.setValue('');
        this.commentsInput.nativeElement.focus();
      }, 0);

    }

  }

  onSend(content) {

    this.spinnerService.show();

    var question = this.questionToken;



    if (content.length > 0) {


      var replyToken = this.replyToken;
      console.log("sss");


      var timeNow: any;

      timeNow = new Date();

      var currentTime =  Math.floor((timeNow -  this.totalTime)/1000);


      this.forumApiService.addTopic(question, content, replyToken, currentTime).subscribe(
        data => {

          setTimeout(() => {
            this.spinnerService.hide();
          }, 500);

          this.showMePartially = false;
          this.refresh.emit('complete');
          window.location.reload();


        });


    }else{
      alert("Necesitamos un comentario");
    }

  }

  openMic() {
    if (this.openO) {
      this.openO = false;
      window.location.href = 'close://microphone';
    } else {
      this.openO = true;
      window.location.href = 'open://microphone';
    }
  }

  placeHolderComment() {
    if (this.speechText.value == 'Toca el icono para iniciar...') {
      this.speechText.setValue('');
    } else {
      console.log('sss2');
    }
  }

  autogrow() {
    let textArea = document.getElementById('comments');
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  writeMessage(message) {
    console.log("add");
    let textArea = <HTMLInputElement>document.getElementById('comments');
    textArea.value = message;
    this.autogrow();
  }


  applyStyles() {

    const styles = {
      'background-color': this.getBgcolor1(),
      'position': 'fixed',
      'bottom': '0px',
      'width': '100%',
      'max-width': '700px',
      'padding-left': '0px'
    };
    return styles;
  }

}
