import {Component, Input, OnInit} from '@angular/core';

import {BaseComponent} from '../base/base.component';
import {NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})


export class FooterComponent  extends BaseComponent  {
  @Input() isGrowth: boolean;

  ngOnInit() {
    this.onCheckUser();
   this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.account = this.trainingService.getCurrentAcount();

    const questionToken = this.route.snapshot.params['token'];





  }




  applyStyles() {
    const styles = {'background-color' :  this.getBgcolor1()};
    return styles;
  }

  isFundae(){

  }



}
