import { Component, OnInit } from '@angular/core';
import {PlanComponent} from '../plan/plan.component';

@Component({
  selector: 'app-potencial',
  templateUrl: './potencial.component.html',
  styleUrls: ['./potencial.component.scss']
})
export class PotencialComponent extends PlanComponent {

 

  ngOnInit() {
    this.setTitle("Recomendación");
  	this.getBgcolor();
  this.getMetricPis2();
  }


 


}
