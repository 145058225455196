import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';

@Component({
  selector: 'app-notitifications',
  templateUrl: './notitifications.component.html',
  styleUrls: ['./notitifications.component.scss']
})
export class NotitificationsComponent extends BaseComponent{

  ngOnInit() {

    this.getBgcolor();
  }

}
