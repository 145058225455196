import {Injectable} from '@angular/core';

import {BaseService} from '../services/base.service';


import {LoginInterface} from '../models/login-interface';

import {isNullOrUndefined} from 'util';

import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthService extends BaseService {

  setDevice(device_string): void {
    localStorage.setItem('currentDevice', device_string);
  }

  setIsAdmin(is_admin): void {
    localStorage.setItem('currentAdmin', is_admin);
  }

  setIsRevision(is_revision): void {
    localStorage.setItem('currentRevision', is_revision);
  }


  setGroup(group): void {
    localStorage.setItem('currentGroup', group);
  }


  setTokenDevice(token_device_string): void {
    localStorage.setItem('currentTokenDevice', token_device_string);
  }

  retrive(e: string): Observable<any> {
    const url_api = `${this.getEndPoint()}/api/v1/recover`;
    return this.http
      .post<LoginInterface>(
        url_api,
        {e},
        {headers: this.headers}
      )
      .pipe(map(data => data));
  }

  loginuser(e: string, p: string, n: string): Observable<any> {
    const url_api = `${this.getEndPoint()}/api/v1/auth_impacto`;
    return this.http
      .post<LoginInterface>(
        url_api,
        {e, p, n},
        {headers: this.headers}
      )
      .pipe(map(data => data));
  }

  verifyUser(token: string, group: string, replyToken: string, redirect: string) {
    const url_api = `${this.getEndPoint()}/api/v1/verify_token/${token}?group_id=${group}&replyToken=${replyToken}&redirect=${redirect}`;
    return (this.user = this.http.get<any>(url_api).pipe(map(data => data)));
  }

  verifyUserPreview(token: string) {
    const url_api = `${this.getEndPoint()}/api/v1/verify_token/${token}`;
    return (this.user = this.http.get<any>(url_api).pipe(map(data => data)));
  }

  setUser(user: LoginInterface): void {
    let user_string = JSON.stringify(user);
    console.log('asignando');
    localStorage.setItem('currentUser', user_string);
  }

  setToken(token: string): void {
    localStorage.setItem('accessToken', token);
  }

  setCourseCycle(cycle: string): void {
    localStorage.setItem('courseCycle', cycle);
  }

  setCourseToken(token: string): void {
    localStorage.setItem('accessCourseToken', token);
  }

  setFranally(franally: string): void {
    localStorage.setItem('isFranally', franally);
  }


}
