import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent extends BaseComponent {

  questions: any;

  categories: any;
  data: any;

  content: any;



  ngOnInit() {

    window.scroll(0,0);
    this.spinnerService.show();
    this.getBgcolor();
    this.trainingService.getResults().subscribe(
          datax => {

            this.data = datax;

            this.content = this.data.content;

        setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);

    });
  }

}
