import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';

@Component({
  selector: 'app-list-objetives',
  templateUrl: './list-objetives.component.html',
  styleUrls: ['./list-objetives.component.scss']
})
export class ListObjetivesComponent extends BaseComponent{

  completed: string;

  objetives: any;

  plan_percentage: any; _

  getProgress(objetive: any){

    switch (objetive.type_objetive) {
      case 'numerico':
         this.plan_percentage = ((objetive.plan_pogress/objetive.plan_finished)*100).toFixed(0);

         break;
      default:
        this.plan_percentage = objetive.plan_pogress;
        break;
    }

    return this.plan_percentage + "%";
  }


  ngOnInit() {
    window.scroll(0,0);
    this.setTitle("Objetivos");

    this.spinnerService.show();

     this.account = this.trainingService.getCurrentAcount();


    this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
    };

    this.trainingService.setViewedObjetives(1);


    this.completed = this.route.snapshot.params['completed'];

    this.getBgcolor();



      console.log(this.completed);

      this.objetiveService.getObjetives(this.completed).subscribe(
          data => {
            this.objetives = data;

             setTimeout(()=>{
          this.spinnerService.hide();
        }, 500);

          });



  }

  onOpen(objetive: any){
    window.scroll(0,0);
    this.trainingService.setCurrentObjetive(objetive);

    this.router.navigate([`/training/objetives/edit_objetive`, objetive.token]);
  }

   onCompleted(){

    window.scroll(0,0);
    this.router.navigate([`/training/objetives/list`,
    {completed: (this.completed == 'completed') ? 'normal' : 'completed'}]);

   }

   onNext(){
    window.scroll(0,0);
     this.calculateNextView();
   }

   applyStyles() {
    //console.log("bg2 " + this.getBgcolor1());
    const styles = {'background-color' :  this.getBgcolor1(), 'position':'fixed', 'bottom':'0px','width': '100%', 'max-width':'700px', 'padding-left':'0px'};
    return styles;
  }

}
