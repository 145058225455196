import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { isError } from 'util';
import { DataApiService } from '../../../services/data-api.service';
import {BaseComponent} from '../../shared/base/base.component';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent extends BaseComponent {

	public isError = false;

  ngOnInit() {
  	window.scroll(0,0);
    this.spinnerService.show();
    this.dataApiService.reset().subscribe(reset => {
    	console.log("hee");
    	  setTimeout(()=>{
    	    this.spinnerService.hide();
          console.log("hee2");
				}, 1000);

    },
        error => {
      console.log(error);
      this.onIsError();
        }
    );
  }



  onIsError(): void {
    console.log("error");
      this.isError = true;
      setTimeout(() => {
        this.isError = false;
        this.spinnerService.hide();
        console.log("error2");
      }, 2000);
  }


}
