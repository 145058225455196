import { Component, OnInit } from '@angular/core';
import { DataApiService } from 'src/app/services/data-api.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserInterface } from 'src/app/models/user-interface';

import { Location } from '@angular/common';
import { isError } from 'util';


@Component({
  selector: 'app-verify-token',
  templateUrl: './verify-token.component.html',
  styleUrls: ['./verify-token.component.scss']
})
export class VerifyTokenComponent implements OnInit {

  constructor(private dataApiService: DataApiService, private route: ActivatedRoute, private router: Router, private location: Location) { }

  public isError = false;

  user: UserInterface = {
    token: '',
    firstname: '',
    lastname: '',
    franchise: ''
  };

  ngOnInit() {
    const user_token = this.route.snapshot.params['token'];
    const bgcolor = this.route.snapshot.params['bg1'];
    const bgcolor2 = this.route.snapshot.params['bg2'];

    this.verifyToken(user_token, bgcolor, bgcolor2);
  }

 	verifyToken(user_token: string, bgcolor: string, bgcolor2: string) {
 		console.log("HOLA");
 		return this.dataApiService
        .verifyToken(user_token)
        .subscribe(
        data => {
        	this.user = data

       
        	this.dataApiService.setUser(this.user);
          this.dataApiService.setFranchise(this.user.franchise);
          this.dataApiService.setToken(this.user.token);

          this.dataApiService.setBgcolor(bgcolor, bgcolor2);



          setTimeout(() => {
            window.location.href = "/?" + (new Date).getTime();
          }, 300); // Activate after 5 minutes.

        
          //this.router.navigate(['/'], { queryParams: { t: (new Date).getTime() } });
          //location.reload();
          
        },
        error => this.onIsError()
		);

   
	}

	onIsError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 4000);
}

}
