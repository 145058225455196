import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';

@Component({
  selector: 'app-list-forum',
  templateUrl: './list-forum.component.html',
  styleUrls: ['./list-forum.component.scss']
})
export class ListForumComponent extends BaseComponent {

  questions: any;
  course_id: any;

  htmlToPlaintext(text) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }

  ngOnInit() {

    window.scroll(0,0);
    this.getBgcolor();
    this.spinnerService.show();

    //const courseId = this.route.snapshot.params['token'];

    this.course_id = this.route.snapshot.paramMap.get('course_id');

    this.forumApiService.getForums(this.course_id).subscribe(data => {
      this.questions = data;
      setTimeout(()=>{
        this.spinnerService.hide();
      }, 500);

    });
  }

   backClicked(){
    this.location.back();
  }

  applyStyles() {
    //console.log("bg2 " + this.getBgcolor1());
    const styles = {'background-color' :  this.getBgcolor1(), 'position':'fixed', 'bottom':'0px','width': '100%', 'max-width':'700px', 'padding-left':'0px'};
    return styles;
  }

}
