import { Component, OnInit } from '@angular/core';
import {PlanComponent} from '../plan/plan.component';

@Component({
  selector: 'app-filter-plans',
  templateUrl: './filter-plans.component.html',
  styleUrls: ['./filter-plans.component.scss']
})
export class FilterPlansComponent  extends PlanComponent {

 

  ngOnInit() {
  	this.getBgcolor();

  	this.route.queryParams.subscribe(params => {

  	this.getListPlansFilter(params["ids"]);
	  })


  }

}
