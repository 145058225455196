import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-valoration',
  templateUrl: './valoration.component.html',
  styleUrls: ['./valoration.component.scss']
})
export class ValorationComponent extends BaseComponent{

  r1 = new FormControl('');

  speechText = new FormControl('');

  openO = false;

  selectOption2 = "";


  isDevice = false;

  ngOnInit() {
    window.scroll(0,0);
    this.getBgcolor();
    this.setTitle("Valorar pregunta");

    if (this.trainingService.getDevice()){
      this.isDevice = true;
    }

     window.writeMessage = this.writeMessage.bind(this);

    this.speechText.setValue("Toca el icono para iniciar...");
  }

  selectOption(event: any){
    if(event.target.checked) {
      this.selectOption2 = event.target.value;
    }
  }

   openMic(){
    if (this.openO){
      this.openO = false;
      window.location.href = "close://microphone";
    }else{
      this.openO = true;
      window.location.href = "open://microphone";
    }
  }

  placeHolderComment(){
    if (this.speechText.value == "Toca el icono para iniciar..."){
      this.speechText.setValue("");
    }else{
       console.log("sss2");
    }
  }

  autogrow() {
    let textArea = document.getElementById("comments")
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  writeMessage(message){
    this.speechText.setValue(message);
    this.autogrow();
  }

  onNext(){
    window.scroll(0,0);
    this.spinnerService.show();
    var question = this.trainingService.getCurrentQuestion();

    var rating = this.selectOption2;
    var content = this.speechText.value;

    this.trainingService.addValoration(question.token, '100', rating, content).subscribe(
          data => {
              console.log(data);

                setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);

             this.calculateNextView();
        });
  }
}
