import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../shared/base/base.component';

@Component({
  selector: 'app-font-size',
  templateUrl: './font-size.component.html',
  styleUrls: ['./font-size.component.scss']
})
export class FontSizeComponent extends BaseComponent {

  public font_size: number = 15;



  ngOnInit() {
    window.scroll(0,0);
    this.font_size = parseInt(this.dataApiService.getFontSize());
  }

  getUp(){
    this.changeFontSize(1);
  }

  getDown(){
     this.changeFontSize(-1);
  }

  changeFontSize(direction){
    var $affectedElements = $("body");

    var fs: number = this.font_size;

    if (fs <= 15) {
      this.font_size = 15;
    }

    if (fs >= 25) {
      this.font_size = 24;
    }
    if (fs >= 15 && fs <= 25) {
      this.font_size = fs + direction;
      console.log("SUMANDO Y RESTANTE " + this.font_size);
    }else{
      console.log("ESTATICO " + this.font_size);
    }

    $affectedElements.each( function(){
      var $this = $(this);
      $this.data("orig-size", $this.css("font-size") );
    });

    var _this = this;

    $affectedElements.each( function(){
      var $this = $(this);

      if (fs >= 15 && fs < 25) {
        $this.css( "font-size" , _this.font_size  );
      }
    });

    console.log(fs);
    this.trainingService.setFontSizePx(this.font_size);
    this.trainingService.setFontSize(this.font_size).subscribe(data=>{
        console.log("ok");



    });

  }


}
