import { Component, OnInit } from '@angular/core';
import {PlanComponent} from '../plan/plan.component';

@Component({
  selector: 'app-value-plan',
  templateUrl: './value-plan.component.html',
  styleUrls: ['./value-plan.component.scss']
})
export class ValuePlanComponent extends PlanComponent{



  ngOnInit() {
    window.scroll(0,0);

    this.spinnerService.show();

  	this.getBgcolor();

    const plan_id = this.route.snapshot.params['id'];

    this.dataApiService.getPlanById(plan_id).subscribe(plan => {
      (<any>this.plan) = plan;

      this.brainstormings = (<any>this.plan).antes.brainstormings;
      this.surveys = (<any>this.plan).antes.surveys;
      this.valorations = (<any>this.plan).antes.valorations;

      console.log("this.brainstormings.length " + this.brainstormings.length);

      if (this.brainstormings.length > 0){
        this.router.navigate([`growth/plan/${(<any>this.plan).token}/value/0/brainstormings`]);

      }else if(this.surveys.length > 0){

         this.router.navigate([`growth/plan/${(<any>this.plan).token}/value/0/surveys`]);

      }else if(this.valorations.length > 0){
         this.router.navigate([`growth/plan/${(<any>this.plan).token}/value/0/valorations`]);
      }

      setTimeout(()=>{
        this.spinnerService.hide();
      }, 500);
    });

  }


}
