import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import {Page404Component} from './components/page404/page404.component';

import {VerifyTokenComponent} from './components/growth/verify-token/verify-token.component';

import {ListPlanComponent} from './components/growth/plans/list-plan/list-plan.component';
import {MorePlansComponent} from './components/growth/plans/more-plans/more-plans.component';
import {DetailsPlanComponent} from './components/growth/plans/details-plan/details-plan.component';
import {StartPlanComponent} from './components/growth/plans/start-plan/start-plan.component';
import {FinishedPlanComponent} from './components/growth/plans/finished-plan/finished-plan.component';

import {FinishedPlansComponent} from './components/growth/plans/finished-plans/finished-plans.component';


import { FilterPlanComponent } from './components/growth/plans/filter-plan/filter-plan.component';

import { KpiComponent } from './components/growth/kpi/kpi.component';

import { BenchComponent } from './components/growth/bench/bench.component';


import { ValorationPlanComponent } from './components/growth/plans/valoration-plan/valoration-plan.component';
import { ResetComponent } from './components/growth/reset/reset.component';
import { ObjetiveComponent } from './components/growth/plans/objetive/objetive.component';

import { ObjetivesComponent } from './components/growth/plans/objetives/objetives.component';

import { FilterPlansComponent } from './components/growth/plans/filter-plans/filter-plans.component';


import { PotencialComponent } from './components/growth/plans/potencial/potencial.component';

import { PotencialPlansComponent } from './components/growth/plans/potencial-plans/potencial-plans.component';


//training

import { LoginComponent } from './components/login/login.component';
import { FactoryComponent } from './components/factory/factory.component';
import { AuthGuard } from './guards/auth.guard';


import { QuestionComponent } from './components/training/question/question.component';

import { AnsweredComponent } from './components/training/answered/answered.component';
import { ValorationComponent } from './components/training/valoration/valoration.component';

import { DashboardComponent } from './components/training/dashboard/dashboard.component';
import { LeaderboardComponent } from './components/training/leaderboard/leaderboard.component';
import { DevelopmentComponent } from './components/training/development/development.component';
import { AnnouncementsComponent } from './components/training/announcements/announcements.component';

import { SurveyComponent } from './components/training/contributions/survey/survey.component';
import { BrainstormingComponent } from './components/training/contributions/brainstorming/brainstorming.component';
import { ArticlesComponent } from './components/training/articles/articles.component';
import { NotitificationsComponent } from './components/training/notitifications/notitifications.component';
import {ListObjetivesComponent} from './components/training/objetives/list-objetives/list-objetives.component';
import {PreObjetiveComponent} from './components/training/objetives/pre-objetive/pre-objetive.component';
import {FormObjetiveComponent} from './components/training/objetives/form-objetive/form-objetive.component';
import {NewObjetiveComponent} from './components/training/objetives/new-objetive/new-objetive.component';
import {RecoverComponent} from './components/recover/recover.component';
import {AddForumComponent} from './components/fundae/forum/add-forum/add-forum.component';
import {ListForumComponent} from './components/fundae/forum/list-forum/list-forum.component';
import {ChatBoxComponent} from './components/fundae/chat/chat-box/chat-box.component';
import {AddChatComponent} from './components/fundae/chat/add-chat/add-chat.component';
import {AutoLoginComponent} from './components/auto-login/auto-login.component';
import {ChatListComponent} from './components/fundae/chat/chat-list/chat-list.component';
import {ContentIndexComponent} from './components/fundae/content-index/content-index.component';
import {UsersAvailablesComponent} from './components/fundae/users-availables/users-availables.component';

import {ValuePlanComponent} from './components/growth/plans/value-plan/value-plan.component';
import {AntesBrainstormingComponent} from './components/growth/plans/antes-brainstorming/antes-brainstorming.component';
import {AntesSurveyComponent} from './components/growth/plans/antes-survey/antes-survey.component';
import {AntesValorationComponent} from './components/growth/plans/antes-valoration/antes-valoration.component';
import {FirebaseComponent} from './components/firebase/firebase.component';
import {FontSizeComponent} from './components/font-size/font-size.component';
import {AutoLoginPreviewComponent} from './components/auto-login-preview/auto-login-preview.component';
import {ResultsComponent} from './components/training/results/results.component';
import {NotificationsComponent} from './components/notifications/notifications.component';



const routes: Routes = [
  { path: '', component: FactoryComponent, canActivate: [AuthGuard]},

  { path: 'add_firebase_token/:token/:name_device', component: FirebaseComponent },

  { path: 'login', component: LoginComponent },

  { path: 'login/:token/:name_device', component: LoginComponent },

  { path: 'font-size', component: FontSizeComponent },

  { path: 'auto-login-preview/:token', component: AutoLoginPreviewComponent },


  { path: 'auto-login/:token/:redirect/group/:group', component: AutoLoginComponent },

  { path: 'auto-login/:token/:redirect/:item/group/:group', component: AutoLoginComponent },

  { path: 'auto-login/:token/:redirect/:item/group/:group/:replyToken', component: AutoLoginComponent },


  { path: 'retrieve', component: RecoverComponent },

  { path: 'notifications', component: NotificationsComponent},

  { path: 'fundae/forum/list', component: ListForumComponent},
  { path: 'fundae/forum/:token/:replyToken', component: AddForumComponent},
  { path: 'fundae/forum/:token', component: AddForumComponent},
  { path: 'fundae/chat/:token', component: ChatBoxComponent},
  { path: 'fundae/chat-list', component: ChatListComponent},
  { path: 'fundae/chat', component: ChatBoxComponent},
  { path: 'fundae/index', component: ContentIndexComponent},
  { path: 'fundae/availables', component: UsersAvailablesComponent},
  { path: 'training/question/:token/pildora', component: QuestionComponent},
  { path: 'training/question/:token', component: QuestionComponent},
  { path: 'training/answered/:token', component: AnsweredComponent},
  { path: 'training/valoration/:token', component: ValorationComponent},
  { path: 'training/dashboard', component: DashboardComponent},
  { path: 'training/announcements', component: AnnouncementsComponent},
  { path: 'training/results', component: ResultsComponent},
  { path: 'training/leaderboard', component: LeaderboardComponent},
  { path: 'training/articles', component: ArticlesComponent},
  { path: 'training/notifications', component: NotitificationsComponent},
  { path: 'training/contributions/survey/:token', component: SurveyComponent},
  { path: 'training/objetives/list', component: ListObjetivesComponent},
  { path: 'training/objetives/new_objetive', component: NewObjetiveComponent},
  { path: 'training/objetives/pre_objetive', component: PreObjetiveComponent},
  { path: 'training/objetives/edit_objetive/:token', component: FormObjetiveComponent},
  { path: 'training/contributions/brainstorming/:token', component: BrainstormingComponent},
  { path: 'training/development', component: DevelopmentComponent},

  { path: 'growth', component: ListPlanComponent },
  { path: 'growth/bench', component: BenchComponent },
  { path: 'growth/reset', component: ResetComponent },
  { path: 'growth/kpis', component: KpiComponent },
  { path: 'growth/filter-plans', component: FilterPlanComponent },
  { path: 'growth/filter-plans-search', component: FilterPlansComponent },
  { path: 'growth/more-plans', component: MorePlansComponent },
  { path: 'growth/finished-plans', component: FinishedPlansComponent },
  { path: 'growth/objetives', component: ObjetivesComponent },
  { path: 'growth/potencial-plans/:id', component: PotencialPlansComponent },
  { path: 'growth/potencial', component: PotencialComponent },
  { path: 'growth/valoration/:id', component: ValorationPlanComponent },
  { path: 'growth/finished-plan/:id', component: FinishedPlanComponent },
  { path: 'growth/plan/:id', component: DetailsPlanComponent },
  { path: 'growth/plan/:id/value', component: ValuePlanComponent },
  { path: 'growth/plan/:id/value/:index/brainstormings', component: AntesBrainstormingComponent},
  { path: 'growth/plan/:id/value/:index/surveys', component: AntesSurveyComponent},
    { path: 'growth/plan/:id/value/:index/valorations', component: AntesValorationComponent},

  { path: 'growth/start-objetive/:id', component: ObjetiveComponent },
  { path: 'growth/start-plan/:id', component: StartPlanComponent },
  { path: 'growth/start-plan/:plan/:id', component: ObjetiveComponent },
  { path: 'growth/verify-token/:token/:bg1/:bg2', component: VerifyTokenComponent },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
