import {Component, OnInit, Input, ChangeDetectorRef, ElementRef} from '@angular/core';

import { ActivatedRoute, Params, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';
import { TrainingService } from 'src/app/services/training.service';

import {DatePipe, Location} from '@angular/common';

import { DataApiService } from 'src/app/services/data-api.service';
import {DomSanitizer, Title} from '@angular/platform-browser';

import { Observable } from 'rxjs/internal/Observable';
import {NgxSpinnerService} from 'ngx-spinner';
import {ObjetiveService} from '../../../services/objetive.service';
import {NgbCalendar, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import {ForumApiService} from 'src/app/services/forum-api.service';
import {ChatService} from '../../../services/chat.service';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})

export class BaseComponent implements OnInit {

  public feed: any;
  public questions: any;
  public question: any;
  public surveys: any;
  public notices: any;
  public brainstormings: any;
  public account: any;

	public isLogged = false;
  public isGrowth = false;

  bgcolor10 = '';
  bgcolor20 = '';
  bgcolor30 = '';
  color10 = '';
  actionsbg = '';
  actionsborder = '';
  component_name = "";

  img: any;

  onImageLoad() {
      // Do what you need in here
  }

  setDimensions(src, id_image, he30){

			this.img = new Image();
			var image_width = 0;
			var image_height = 0;

			var device_width = $('.root_container2').width();
			var device_height = $(document).height();

			var new_image_width = 0;
			var new_image_height = 0;

			this.img.onload = function () {

			  let that = this;

				image_width = that.width;

				image_height = that.height;

				console.log("IMAGEN ancho imagen " + image_width);
				console.log("IMAGEN alto imagen " + image_height);

				var height_30 = device_height * he30;

				console.log("IMAGEN 30% del alto del dispositivo " + height_30);

				if (image_height > height_30) {
					console.log("IMAGEN image_height " + image_height + " es mayor a  30% " + height_30);

					var ratio = image_width / image_height;

					var d = height_30;
					var float_height_30 = d;

					new_image_height = float_height_30;

					new_image_width = new_image_height * ratio;

					console.log("IMAGEN nuevo ancho de la imagen es " + new_image_width + " basado en el 30% " + height_30);

				} else {
					console.log("IMAGEN image_height " + image_height + " no es mayor a  30% " + height_30);

					new_image_width = image_width;
					new_image_height = image_height;

				}

				var width_60 = device_width * 0.80;

				console.log("IMAGEN 60% del ancho del dispositivo es " + width_60);

				if (new_image_width > width_60) {

					var ratio2 = image_height / image_width;

					var x = width_60;
					var float_width_60 = x;

					new_image_width = float_width_60;

					new_image_height = new_image_width * ratio2;

				}
				console.log("IMAGEN nuevo ancho imagen " + new_image_width);
				console.log("IMAGEN nuevo alto imagen " + new_image_height);
				$(id_image).attr("style", "width:" + new_image_width + "px;height:" + new_image_height + "px");

			}

			this.img.src =  src;

  }

  getComponentName():void{
    this.router.events.subscribe((e) => {
		  if (e instanceof NavigationEnd) {
		    this.component_name = e.url;
         //console.log(this.component_name);
        if (this.component_name.indexOf("growth") !== -1){
          console.log("hola growth1");
          this.isGrowth = true;
        }else{
          this.isGrowth = false;
        }

		    switch(this.component_name) {
				  case "/login":
				    this.component_name = "login";
				    break;

				  default:
				     this.component_name = this.component_name.replace("/training/", "").replace("/", " ").replace("/", " ").replace(";", " ");

				     this.component_name = this.component_name.replace("/", " ");

				     console.log(this.component_name);
				}
		  }
		});

  }

  constructor( public cdref: ChangeDetectorRef,public modalService: NgbModal, public chatService: ChatService, public forumApiService: ForumApiService, public trainingService: TrainingService, public titleService : Title ,public dataApiService: DataApiService, public authService: AuthService, public location: Location, public router: Router, public route: ActivatedRoute, public spinnerService: NgxSpinnerService, public objetiveService: ObjetiveService, public calendar: NgbCalendar, public datePipe: DatePipe, public deviceService: DeviceDetectorService, public confirmationDialogService: ConfirmationDialogService, public sanitizer: DomSanitizer, public eRef: ElementRef) { }

  ngOnInit() {}


  public setTitle(newTitle : string) {
		this
			.titleService
			.setTitle(newTitle);
	}

  formatTitle(title){

    var data = [];
    var supTitle = "";

    if (title.match(/---(.*)---(.*)/))
    {
      data = title.match(/---(.*)---(.*)/)
      supTitle = data[1];
      title = data[2];
    }else{
      supTitle = "";
      title = title;
    }

    return {suptitle: supTitle, title: title};
  }

  calculateNextView(){
    this.spinnerService.show();


    this.feed = this.trainingService.getCurrentFeed();

    console.log("this.feed " + this.feed.length);

    if (this.feed){

      this.questions = this.feed.questions;

      this.surveys = this.feed.surveys;

      this.notices = this.feed.notices;

      this.account = this.feed.account;

      this.brainstormings = this.feed.add_brainstormings;

      console.log("this.questions " + this.questions.length);

      //this.trainingService.setFontSizePx(this.feed.user.font_size);

      if (this.account.fundae){
        this.trainingService.setFundae(1);
      }else{
        this.trainingService.setFundae(0);
      }

      if (this.questions.length > 0){
        this.trainingService.setViewedAnnouncements(0);
        this.trainingService.setViewedObjetives(0);
        this.trainingService.setViewedBrainstorming(0);
        this.trainingService.setQuestionIndex(0);

        if (this.account.evaluation_on){
          this.trainingService.setEvaluation(1);
        }else{
          this.trainingService.setEvaluation(0);
        }


        if (this.questions[0].type_question == "pildora"){

          this.router.navigate([`/training/question/${this.questions[0].token}/pildora`]);
        }else{
          this.router.navigate(["/training/question", this.questions[0].token]);
          //this.location.go(`/training/question/${this.questions[0].token}`);
        }

        } else if(this.surveys.length > 0) {



        this.router.navigate(["/training/contributions/survey", this.surveys[0].token]);

      }else if(this.brainstormings.length > 0 && (parseInt(this.trainingService.getViewedBrainstorming()) == 0)){

         this.router.navigate(["/training/contributions/brainstorming", this.brainstormings[0].token]);




      } else if(this.account.fundae && this.account.evaluation_on){
        this.router.navigate(["/"]);

        this.location.go(`/training/results`);
        location.reload();
      }else {

         this.trainingService.getNotices().subscribe(data => {

           this.notices = data;

            if (this.notices.length > 0 && (parseInt(this.trainingService.getViewedAnnouncements()) == 0)) {

              this.router.navigate([`/training/announcements`]);

            } else {
              this.authService.logoutUser();
              this.location.go("?logout");
              location.reload();
            }

        });


      }
    }else{
         this.authService.logoutUser();
        this.location.go("?logout");

      location.reload();

    }


         setTimeout(()=>{
        this.spinnerService.hide();
      }, 500);
  }

  getBgcolor(){

    this.bgcolor10 = "#" + this.dataApiService.getBgcolor1().replace("%23","");

    if (this.dataApiService.getBgcolor2().replace("%23","") == "ffffff"){
      this.actionsbg = "#f2f2f2";
      this.actionsborder = "box-shadow: none;"
    }else{
      this.actionsbg = "#ffffff";
    }

    if (this.dataApiService.getBgcolor2().replace("%23","") == "ffffff"){
      this.bgcolor20 = "rgba(0, 0, 0, 0.05)";
    }else{

      this.bgcolor20 = this.getGenColor("#" + this.dataApiService.getBgcolor2().replace("%23",""), 20);
    }

     this.bgcolor30 = "#" + this.dataApiService.getBgcolor2().replace("%23","");

    this.color10 =  this.getContrastYIQ(this.bgcolor10);

  }

  getContrastYIQ(hexcolor){
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
  }

  getGenColor(col, amt) {

    var usePound = false;

    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col,16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if  (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if  (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);

  }
  public getTitle() {
    return this
      .titleService
      .getTitle();
  }



  onHome(){
  	this.router.navigate([`/`]);
  }

  onBasic(){
  	window.open("https://web.wiserteams.com", "_self");
  	//this.router.navigate(["https://web.wiserteams.com"]);
  }

  getBgcolor1(){

    if (this.dataApiService.getBgcolor1()){
       return "#" + this.dataApiService.getBgcolor1().replace("%23", "");
    }else{

      return "#330202";
    }
  }

  onCheckUser(): void {
    if (this.authService.getCurrentUser() === null) {
      this.isLogged = false;
    } else {
      this.isLogged = true;
    }
  }
}
