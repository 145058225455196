import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-chat',
  templateUrl: './add-chat.component.html',
  styleUrls: ['./add-chat.component.scss']
})
export class AddChatComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
