import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AddBoxCommentComponent} from '../add-box-comment/add-box-comment.component';

@Component({
  selector: 'app-single-comment',
  templateUrl: './single-comment.component.html',
  styleUrls: ['./single-comment.component.scss']
})
export class SingleCommentComponent implements OnInit {

  @Input() comentario: any;
  @Input() replyToken: any;
  @Input() my_class: any;

  ngOnInit() {

    if (this.comentario.token == this.replyToken){
      this.onShow(this.comentario);
    }
  }
  hideme: any = {};

  @Output() refresh = new EventEmitter();

  onRefresh(event){
    this.refresh.emit('complete');
  }

  onShow(item) {
    Object.keys(this.hideme).forEach(h => {
      this.hideme[h] = false;
    });
    this.hideme[item.id] = true;
  }

}
