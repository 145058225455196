import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../../services/data-api.service';
import { PlanInterface } from '../../../../models/plan-interface';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
 
  constructor(private dataApiService: DataApiService, private router: Router) {}

  plansPast: PlanInterface;
  plansFuture: PlanInterface;
  plansStarted: PlanInterface;
 

  now_date: Date = new Date();

  ngOnInit() {
    console.log(this.dataApiService.getCurrentUser());
    console.log(this.dataApiService.getToken());

    this.getListPlansPast();
    this.getListPlansStarted();
    this.getListPlansFuture();
  }

  getListPlansPast(): void{
    this.dataApiService
    .getAllPlans("1","1","1")
    .subscribe((plans: PlanInterface) => (this.plansPast = plans));
  }
  
  getListPlansFuture(): void{
    this.dataApiService
    .getAllPlans("1","2","1")
    .subscribe((plans: PlanInterface) => (this.plansFuture = plans));
  }
  
  getListPlansStarted(): void{
    this.dataApiService
    .getAllPlans("2","0","1")
    .subscribe((plans: PlanInterface) => (this.plansStarted = plans));
  }

  openPlan(plan: PlanInterface): void {
    this.dataApiService.selectedPlan = Object.assign({}, plan);

    this.router.navigate([`./growth/plan/${plan.id}`]);
  }

}
