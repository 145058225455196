import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent} from '@angular/router';
import {DataApiService} from './services/data-api.service';

import {Title} from '@angular/platform-browser';

import {Location} from '@angular/common';
import {DeviceDetectorService} from 'ngx-device-detector';
import {AuthService} from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'wiserteams';
  bgcolor = '000000';
  bgcolor2 = 'FFFFFF';
  component_name = '';

  public setTitle(newTitle: string) {
    this
      .titleService
      .setTitle(newTitle);
  }

  public currentScale: any;

  public wp_width = 0;

  setScale(scale, tipe = '+') {
    this.wp_width = document.getElementById('wp').getBoundingClientRect().width;

    var cssPrefixesMap = [
      'scale',
      '-webkit-transform',
      '-moz-transform',
      '-ms-transform',
      '-o-transform',
      'transform'];

    var scaleCss = {};

    scaleCss['transform-origin'] = 'top center';

    var _this = this;

    if (tipe == '+') {
      if ($(window).width() >= (this.wp_width)) {
        this.currentScale = parseFloat(this.currentScale) + parseFloat(scale);

        cssPrefixesMap.forEach(function (prefix) {
          scaleCss[prefix] = 'scale(' + _this.currentScale + ')';
        });

        $('.root_container').css(scaleCss);

      }
    } else {

      if (300 <= (this.wp_width - 100)) {

        this.currentScale = parseFloat(this.currentScale) - parseFloat(scale);

        cssPrefixesMap.forEach(function (prefix) {
          scaleCss[prefix] = 'scale(' + _this.currentScale + ')';
        });

        $('.root_container').css(scaleCss);
      } else {

      }
    }
  }

  deviceInfo = null;
  isMobile: Boolean = false;
  isDesktopDevice: Boolean = false;
  isTablet: Boolean = false;
  isLogin: Boolean = false;
  isRevision: Boolean = false;

  isAdmin: Boolean = false;

  font_size: any;
  isGrowth: any;

  constructor(location: Location, private titleService: Title, private _router: Router, private spinnerService: NgxSpinnerService, public dataApiService: DataApiService, private deviceService: DeviceDetectorService, public route: ActivatedRoute, private authService: AuthService) {

    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();

   if ( this.authService.isAdmin() == "1"){
        this.isAdmin = true;
      }else{
        this.isAdmin = false;
      }

    if ( this.dataApiService.isRevision() == "1"){
        this.isRevision = true;
        console.log("this.dataApiService.isRevision() " + this.dataApiService.isRevision());
      }else{
        this.isRevision = false;
      }

    if (this.dataApiService.getCurrentUser()) {
      this.isLogin = true;
    }
  }


  ngOnInit() {



    this._router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
          $('.root_container #sidebar').hide();
        this.component_name = e.url;
        console.log('HOLA ' + this.component_name);
         if (this.component_name.indexOf("growth") !== -1){
          console.log("hola growth1");
          this.isGrowth = true;
        }else{
          this.isGrowth = false;
        }


        if (this.component_name.indexOf('login') > 0) {
          this.component_name = 'login';
        } else if (this.component_name.indexOf('surveys') > 0) {
          this.component_name = 'contributions';


        } else if (this.component_name.indexOf('retrieve') > 0) {
          this.component_name = 'recover';
        } else {

          switch (this.component_name) {

            default:
              this.component_name = this.component_name.replace('/training/', '').replace('/', ' ').replace('/', ' ').replace(';', ' ');

              this.component_name = this.component_name.replace('/', ' ');
          }
        }
      }
    });

    if (this.dataApiService.getBgcolor1()) {
      this.bgcolor = this.dataApiService.getBgcolor1().replace('%23', '');
      this.bgcolor2 = this.dataApiService.getBgcolor2().replace('%23', '');
    }

    document.body.style.backgroundColor = '#' + this.bgcolor2;

    if (this.dataApiService.getFontSize() === null){
      this.font_size = 16;
    }else{
      this.font_size = this.dataApiService.getFontSize();

    }
    document.body.style.fontSize = this.font_size + "px";



    console.log(this.font_size);



  }

  spinner(): void {
    this.spinnerService.show();

    setTimeout(() => {
      this.spinnerService.hide();
    }, 2000);

  }

}
