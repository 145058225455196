import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../../shared/base/base.component';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-objetive',
  templateUrl: './new-objetive.component.html',
  styleUrls: ['./new-objetive.component.scss']
})
export class NewObjetiveComponent extends BaseComponent{

  modelDate: NgbDateStruct;
  date: {year: number, month: number, day: number};


  opt: any;
  opt_normal: boolean = false;
  opt_parciales: boolean = false;
  opt_si_no: boolean = false;
  opt_numerio: boolean = false;


  plan_pogress: any = 0;
  text_objetive: any;
  text_details: any;
  date_close: any;
  plan_finished: any = 0;

  ngOnInit() {
    window.scroll(0,0);
    this.setTitle("Objetivos");
    this.opt = this.route.snapshot.paramMap.get('opt');

    this.getBgcolor();
    this.selectToday();
  }


  autogrow(){
    let  textArea = document.getElementById("newTextObjetive");
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  checkBlock(compare_value){

    if (this.opt == compare_value){
      return true;
    }else{
      return false;
    }
  }

  selectToday() {
     const now = new Date();
    this.modelDate = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
  }

  addPlan(){
        window.scroll(0,0);

        console.log(this.plan_finished);

        console.log(this.text_objetive);
        console.log(this.text_details);
        console.log(this.plan_pogress);



        this.date_close = this.datePipe.transform(new Date(this.modelDate.year,this.modelDate.month-1,this.modelDate.day),"yyyy-MM-dd");
        console.log(this.modelDate);
        console.log(this.date_close);

							if (this.text_objetive == ""){
								alert("Agrege un plan de acción");
								return
							}


							 switch (this.opt) {
								case "numerico":


										if (this.plan_finished == "" || this.plan_finished == "0" || this.plan_finished == 0){
											alert("Agrege un número máximo");
											return
										}

									break;
								case "normal":

										if (this.plan_pogress == "" || this.plan_pogress == "0" || this.plan_pogress == 0){
											alert("Agrege un porcentaje del avance");
											return
										}

									break;
								case "parciales":

								break;
								case "si_no":


								break;

							}

							this.objetiveService.addPlan(this.text_objetive, this.plan_pogress, this.date_close, this.plan_finished,this.text_details, this.opt).subscribe(
              data => {
                  console.log(data);
               this.router.navigate([`/training/objetives/list`]);
            });

  }

  goBack(){
    window.scroll(0,0);
    this.router.navigate([`/training/objetives/list`]);
  }

}
