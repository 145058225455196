import {BrowserModule, Title} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import * as $ from 'jquery';


import { TextareaAutosizeModule } from 'ngx-textarea-autosize';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { NgxSpinnerModule } from 'ngx-spinner';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

// PIPES
import {DatePipe} from '@angular/common';
import {SafePipe} from './pipes/safe.pipe';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';

// SERVICES
import {ObjetiveService} from './services/objetive.service';
import { DataApiService } from 'src/app/services/data-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { TrainingService } from 'src/app/services/training.service';

// PAGES
import { LoginComponent } from './components/login/login.component';
import { Page404Component } from './components/page404/page404.component';

// SHARED
import { BaseComponent } from './components/shared/base/base.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { ConfirmationDialogComponent } from './components/shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './components/shared/confirmation-dialog/confirmation-dialog.service';
import { HeaderComponent } from './components/shared/header/header.component';

// FACTORY
import { FactoryComponent } from './components/factory/factory.component';

//GROWTH
import { HomeComponent } from './components/growth/plans/home/home.component';
import { MorePlansComponent } from './components/growth/plans/more-plans/more-plans.component';
import { DetailsPlanComponent } from './components/growth/plans/details-plan/details-plan.component';
import { StartPlanComponent } from './components/growth/plans/start-plan/start-plan.component';
import { ListPlanComponent } from './components/growth/plans/list-plan/list-plan.component';
import { PlanComponent } from './components/growth/plans/plan/plan.component';

import { VerifyTokenComponent } from './components/growth/verify-token/verify-token.component';

import { FinishedPlanComponent } from './components/growth/plans/finished-plan/finished-plan.component';

import { FilterPlanComponent } from './components/growth/plans/filter-plan/filter-plan.component';

import { KpiComponent } from './components/growth/kpi/kpi.component';
import { BenchComponent } from './components/growth/bench/bench.component';

import { FinishedPlansComponent } from './components/growth/plans/finished-plans/finished-plans.component';
import { ValorationPlanComponent } from './components/growth/plans/valoration-plan/valoration-plan.component';
import { ResetComponent } from './components/growth/reset/reset.component';
import { ObjetiveComponent } from './components/growth/plans/objetive/objetive.component';
import { ObjetivesComponent } from './components/growth/plans/objetives/objetives.component';

import { FilterPlansComponent } from './components/growth/plans/filter-plans/filter-plans.component';
import { PotencialComponent } from './components/growth/plans/potencial/potencial.component';
import { PotencialPlansComponent } from './components/growth/plans/potencial-plans/potencial-plans.component';

// TRAINING
import { QuestionComponent } from './components/training/question/question.component';
import { AnsweredComponent } from './components/training/answered/answered.component';
import { ValorationComponent } from './components/training/valoration/valoration.component';

import { DashboardComponent } from './components/training/dashboard/dashboard.component';
import { LeaderboardComponent } from './components/training/leaderboard/leaderboard.component';
import { DevelopmentComponent } from './components/training/development/development.component';
import { AnnouncementsComponent } from './components/training/announcements/announcements.component';

import { SurveyComponent } from './components/training/contributions/survey/survey.component';
import { BrainstormingComponent } from './components/training/contributions/brainstorming/brainstorming.component';
import { ArticlesComponent } from './components/training/articles/articles.component';
import { NotitificationsComponent } from './components/training/notitifications/notitifications.component';

import { FormObjetiveComponent } from './components/training/objetives/form-objetive/form-objetive.component';
import { ListObjetivesComponent } from './components/training/objetives/list-objetives/list-objetives.component';
import { PreObjetiveComponent } from './components/training/objetives/pre-objetive/pre-objetive.component';

import { NewObjetiveComponent } from './components/training/objetives/new-objetive/new-objetive.component';
import { RecoverComponent } from './components/recover/recover.component';
import { ValuePlanComponent } from './components/growth/plans/value-plan/value-plan.component';

import { AntesBrainstormingComponent } from './components/growth/plans/antes-brainstorming/antes-brainstorming.component';

import { AntesSurveyComponent } from './components/growth/plans/antes-survey/antes-survey.component';
import { AntesValorationComponent } from './components/growth/plans/antes-valoration/antes-valoration.component';

import { AddForumComponent } from './components/fundae/forum/add-forum/add-forum.component';
import { ListForumComponent } from './components/fundae/forum/list-forum/list-forum.component';

import { ChatBoxComponent } from './components/fundae/chat/chat-box/chat-box.component';
import { AddChatComponent } from './components/fundae/chat/add-chat/add-chat.component';
import { AddBoxCommentComponent } from './components/fundae/forum/add-box-comment/add-box-comment.component';
import { SingleCommentComponent } from './components/fundae/forum/single-comment/single-comment.component';
import { AutoLoginComponent } from './components/auto-login/auto-login.component';
import { ChatListComponent } from './components/fundae/chat/chat-list/chat-list.component';
import { ContentIndexComponent } from './components/fundae/content-index/content-index.component';
import { UsersAvailablesComponent } from './components/fundae/users-availables/users-availables.component';
import { FirebaseComponent } from './components/firebase/firebase.component';
import {TruncatePipe} from './pipes/truncate.pipe';
import { FontSizeComponent } from './components/font-size/font-size.component';
import {DeviceDetectorModule} from 'ngx-device-detector';
import { AutoLoginPreviewComponent } from './components/auto-login-preview/auto-login-preview.component';
import { MenuRevisionComponent } from './components/shared/menu-revision/menu-revision.component';
import { ResultsComponent } from './components/training/results/results.component';
import { NotificationsComponent } from './components/notifications/notifications.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MorePlansComponent,
    DetailsPlanComponent,
    StartPlanComponent,
    PlanComponent,
    ListPlanComponent,
    Page404Component,
    HeaderComponent,
    VerifyTokenComponent, 
    ConfirmationDialogComponent, 
    FinishedPlanComponent,
    FilterPlanComponent, 
    KpiComponent, 
    BenchComponent, 
    FooterComponent, 
    SidebarComponent,
    FinishedPlansComponent,
    ValorationPlanComponent,
    ResetComponent,
    ObjetiveComponent,
    ObjetivesComponent,
    EscapeHtmlPipe,
     SafePipe,
     TruncatePipe,
    FilterPlansComponent,
    PotencialComponent,
    PotencialPlansComponent,
    LoginComponent,
    FactoryComponent,
    BaseComponent,
    QuestionComponent,
    AnsweredComponent,
    ValorationComponent,
    SurveyComponent,
    BrainstormingComponent,
    DashboardComponent,
    LeaderboardComponent,
    DevelopmentComponent,
    AnnouncementsComponent,
    ArticlesComponent,
    NotitificationsComponent,
    FormObjetiveComponent,
    ListObjetivesComponent,
    PreObjetiveComponent,
    NewObjetiveComponent,
    RecoverComponent,
    AddForumComponent,
    ListForumComponent,
    ChatBoxComponent,
    AddChatComponent,
    AddBoxCommentComponent,
    SingleCommentComponent,
    AutoLoginComponent,
    ChatListComponent,
    ContentIndexComponent,
    UsersAvailablesComponent,
    ValuePlanComponent,
    AntesBrainstormingComponent,
    AntesSurveyComponent,
    AntesValorationComponent,
    FirebaseComponent,
    FontSizeComponent,
    AutoLoginPreviewComponent,
    MenuRevisionComponent,
    ResultsComponent,
    NotificationsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TextareaAutosizeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgbModule,
      DeviceDetectorModule.forRoot()
  ],
  providers: [
    Title,
    AuthService,
    DataApiService,
    TrainingService,
    DatePipe,
    ObjetiveService,
    ConfirmationDialogService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ ConfirmationDialogComponent ]
})

export class AppModule { }
