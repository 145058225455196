import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent extends BaseComponent{

  token: any;
  url: any;

  ngOnInit() {

    this.getBgcolor();

    this.account = this.trainingService.getCurrentAcount();
    this.setTitle( this.account.menu_articles || "Artículos de Formación");



    this.token = this.trainingService.getCurrentUser().token;

    this.url = `https://wiserteams.com/g7Apfo8bbnFEFN?user_token=${this.token}&bg_color=${this.bgcolor10}`;


  }

}
