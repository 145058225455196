import { Component, OnInit } from '@angular/core';
import {PlanComponent} from '../plan/plan.component';
import {FormControl} from '@angular/forms';
import {BrainstormingInterface} from '../../../../models/brainstorming-interface';

@Component({
  selector: 'app-antes-brainstorming',
  templateUrl: './antes-brainstorming.component.html',
  styleUrls: ['./antes-brainstorming.component.scss']
})
export class AntesBrainstormingComponent extends PlanComponent{


  speechText = new FormControl('');

  openO = false;

  index = 0;

  ngOnInit() {
    window.scroll(0,0);

    this.spinnerService.show();

    const plan_id = this.route.snapshot.params['id'];

    this.index = this.route.snapshot.params['index'];

    this.dataApiService.getPlanById(plan_id).subscribe(plan => {
      this.plan = plan;

      this.brainstormings = (<any>this.plan).antes.brainstormings;
      this.surveys = (<any>this.plan).antes.surveys;
      this.valorations = (<any>this.plan).antes.valorations;

      this.brainstorming = this.brainstormings[this.index];

      window.writeMessage = this.writeMessage.bind(this);
      this.getBgcolor();
      this.speechText.setValue("Toca el icono para iniciar...");

      setTimeout(()=>{
        this.spinnerService.hide();
      }, 500);
    });

  }


  onNextValue2(){
     this.spinnerService.show();
    var token = (<any>this.brainstorming).token;

    var content = this.speechText.value;

    this.trainingService.answeredBrainstorming(token, content).subscribe(
          data => {

    console.log(this.brainstormings);
    console.log(this.index - 1);
    if (this.brainstormings.length  <= (this.index + 1)){
      console.log(this.brainstormings);
      if(this.surveys.length > 0){
        console.log(this.brainstormings);

         return this.router.navigate([`growth/plan/${(<any>this.plan).token}/value/0/surveys`]);

      }else if(this.valorations.length > 0){
        console.log(this.plan);
         return this.router.navigate([`growth/plan/${(<any>this.plan).token}/value/0/valorations`]);
      }
    }else{

       var sum  = (parseInt(<any>this.index) + 1);

        return this.router.navigate([`growth/plan/${(<any>this.plan).token}/value/${sum}/brainstormings`]);
    }
     setTimeout(()=>{
        this.spinnerService.hide();
      }, 500);
});
  }

  openMic(){

    if (this.openO){
      this.openO = false
      window.location.href = "close://microphone";
    }else{
      this.openO = true
      window.location.href = "open://microphone";
    }
  }

  placeHolderComment(){

    if (this.speechText.value == "Toca el icono para iniciar..."){
      this.speechText.setValue("");
    }else{
       console.log("sss2");
    }
  }

  autogrow() {
    let textArea = document.getElementById("comments")
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  writeMessage(message){
    this.speechText.setValue(message);
    this.autogrow();
  }


}
