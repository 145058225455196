import { Component, OnInit } from '@angular/core';
import {PlanComponent} from '../../growth/plans/plan/plan.component';

@Component({
  selector: 'app-content-index',
  templateUrl: './content-index.component.html',
  styleUrls: ['./content-index.component.scss']
})
export class ContentIndexComponent extends PlanComponent{


   public qs:  any = {
     vistos: 0,
     finalizados: 0,
    contents: []
  };
  public user_chat: any;
  public account: any;
  public user: any;

  public chats:  any = {
    user_two: {
      name: "",
      id: 0
    },
    chats: []
  };


  ngOnInit() {

    window.scroll(0,0);
    this.getBgcolor();
    this.spinnerService.show();

    this.user_chat = this.route.snapshot.paramMap.get('user_chat');
    this.account = this.trainingService.getCurrentAcount();
    this.user = this.trainingService.getCurrentUserPublic();

    this.trainingService.getContents().subscribe(data => {

        this.qs = data;

        console.log(this.qs);
        setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);


    });




  }

}
