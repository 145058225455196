import { Component, OnInit } from '@angular/core';
import { MetricPiInterface } from '../../../models/metric-pi-interface';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { isError } from 'util';
import { DataApiService } from '../../../services/data-api.service';
import {PlanComponent} from '../plans/plan/plan.component';
@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss']
})

export class KpiComponent extends PlanComponent{

  values = '';

	metric_pis: MetricPiInterface;

  ngOnInit() {
    this.setTitle("Métricas");

    this.getBgcolor();
  	this.getMetricPis();
  }


  getMetricPis(){
    return this.dataApiService.getMetricPis().subscribe(metric_pis => (this.metric_pis = metric_pis));
  }


  onKey(value: string, id: string) { // without type info
   
    return this.dataApiService.savePKI(id, value).subscribe();
  }
  


}
