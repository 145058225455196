import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../shared/base/base.component';
import {LoginInterface} from '../../models/login-interface';

@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.scss']
})
export class AutoLoginComponent extends BaseComponent{

  carlos: any;


  ngOnInit() {

    this.spinnerService.show();

    const user_token = this.route.snapshot.params['token'];

    const question_token = this.route.snapshot.params['item'];

    const redirect = this.route.snapshot.params['redirect'];

    const group = this.route.snapshot.params['group'];

    const replyToken = this.route.snapshot.params['replyToken'];

    this.authService.setIsAdmin("1");
    this.authService.setGroup(group);

    this.authService.verifyUser(user_token, group, replyToken, redirect).subscribe(
          data => {

            this.authService.setUser(data);

            this.authService.setToken(user_token);

            this.authService.setBgcolor(data.account.bg_color.replace("#",""), data.account.bg_color2.replace("#",""));


            this.trainingService.getFeed().subscribe(
          data => {
              this.trainingService.setFeed(data);
              console.log("replyToken " + replyToken);

              if (redirect != "redirect_chat") {

                if (redirect == "redirect_inside_chat") {

                   this.router.navigate(['/fundae/chat-list/']).then(() => {
                      window.location.reload();
                    });



                }else{
                  if (replyToken === undefined ) {

                    if (redirect == "redirect_pildora") {


                        this.router.navigate([`/training/question/${question_token}/pildora`]).then(() => {
                        window.location.reload();
                      });
                  }else if( redirect == "redirect_question"){

                      this.router.navigate(["/training/question", question_token]).then(() => {
                          window.location.reload();
                        });



                    }else{

                      this.router.navigate(['/fundae/forum/', question_token]).then(() => {
                        window.location.reload();
                      });
                    }

                  } else {
                    this.router.navigate(['/fundae/forum/', question_token, replyToken]).then(() => {
                      window.location.reload();
                    });

                  }
                }

              }else{
                this.router.navigate(["/fundae/chat/", {user_chat: question_token}]).then(() => {
    window.location.reload();
  });



              }
              //location.reload();
                  setTimeout(() => {
      this.spinnerService.hide();
    }, 1000);
            });
          });

       /*this.authService.verifyUser(user_token).subscribe(
          data => {
              console.log(data);
          //     this.authService.setUser(data);
          //
          //     const token = data.token;
          //     this.authService.setToken(token);
          //
          //     this.authService.setBgcolor(data.account.bg_color.replace("#",""), data.account.bg_color2.replace("#",""));
          //
          //      this.trainingService.getFeed().subscribe(
          // data => {
          //
          //         this.trainingService.setFeed(data);
          //         this.location.go(`/fundae/forum/${question_token}`);
          //         location.reload();
          //
          //         setTimeout(() => {
          //           this.spinnerService.hide();
          //         }, 1000);
          //     });


            });*/

  }

}

