import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {BaseComponent} from '../shared/base/base.component';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent extends BaseComponent {

  public recover: any = {
    e: ''
  };

  ngOnInit() {
    this.spinnerService.show();
    setTimeout(() => {
        this.spinnerService.hide();
      }, 500);
  }

  onRetrieve(form: NgForm) {

    this.spinnerService.show();

    if (!this.recover.e){
       setTimeout(()=>{
        this.spinnerService.hide();
      }, 500);
      return alert("Coloque su email para recuperar la contraseña");
    }else {

      return this.authService
        .retrive(this.recover.e)
        .subscribe(
          data => {
              alert("Se ha enviado un email para restablecer la contraseña");
               this.location.go(`/login?r=${(new Date).getTime()}`);
              location.reload();
          });
    }

  }


}
