import {AfterViewInit, Component, OnInit} from '@angular/core';

import { BaseComponent } from 'src/app/components/shared/base/base.component';

import { Observable } from 'rxjs/internal/Observable';
import {NavigationEnd} from '@angular/router';

declare var audiojs: any;

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})

export class QuestionComponent extends BaseComponent {

  private my_answer: any = {
    answer: ''
  };

  private data_answered: any = {
    points: 0
  };

  correct_multiple: any;

  nextQuestion: string;

  questionIndex: any;

  questionPercentage: any;

  checkedList = [];

  checkedListCorrect = [];

  totalTime: any;

  parcial_multiple: boolean = false;
  feed: any;

  message_bg: string = "FDCFCC";
  existAudio = false;

  getContent(question){

    if (question.includes("<audio")){
      return question;
    }else{
      var q = question;
      return this.sanitizer.bypassSecurityTrustHtml(q);
    }
  }

  contentHtml = "";

  public isAdmin: boolean = false;

  public isEvaluation: boolean = false;

  ngOnInit(){
    window.scroll(0,0);
    this.spinnerService.show();
    if (this.trainingService.getCurrentAcount() === null){

      this.authService.logoutUser();
      this.location.go("?logout");
        setTimeout(()=>{
            this.spinnerService.hide();
          }, 500);
        location.reload();
    }

    if (this.trainingService.isEvaluation() == "1"){
      this.isEvaluation = true;
    }


    this.account = this.trainingService.getCurrentAcount();

    this.setTitle(this.account.name);

    this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
    };

    this.trainingService.setAnswered("");



    var _this = this;

    this.correct_multiple = false;

    this.totalTime = new Date();

    const questionToken = this.route.snapshot.params['token'];

    this.questions = this.trainingService.getCurrentQuestions();

    var f: number;

    this.question = this.questions.find(function(item, index) {
      f = index;
      return item.token === questionToken;
    });

    this.questionIndex = f;

    console.log("this.questions.length " + this.questions.length);

    console.log("this.questionIndex " + this.questionIndex);

    if (f == 0){
      this.questionPercentage = 0;
    }else{

      this.questionPercentage = parseInt(this.questionIndex)*100/parseInt(this.questions.length);

      this.questionPercentage = parseInt(this.questionPercentage);
    }

    this.getBgcolor();

    //this.nextQuestion = this.questions[this.questionIndex].token;

    this.trainingService.setCurrentQuestion(this.question);

    this.setDimensions(`https://old.wiserteams.com${this.question.image.url}`, "#brain_image", 0.26);



     if ( this.authService.isAdmin() == "1"){
        this.isAdmin = true;
      }else{
        this.isAdmin = false;
      }

    if (this.question.question.includes("<audio")){
      this.existAudio = true;
    }
    if (this.question.type_question == 'multiple'){

      this.question.answers.find(function(item, index) {
        if (item.correct){
          _this.checkedListCorrect.push(item.token);
        }
      });
    }

    if (this.trainingService.getCourseCycle() == "1"){
      this.contentHtml = this.getContent(this.question.question);
    }else{

      if ((this.question.question_alternative.length == 0)){

        this.contentHtml = this.getContent(this.question.question);
      }else{
        this.contentHtml = this.getContent(this.question.question_alternative);
      }
    }

    if (this.question.message.indexOf("mal") !== -1){
      this.message_bg = "alert alert-danger";
    }else{
      this.message_bg = "alert alert-success";
    }
    this.feed = this.trainingService.getCurrentFeed();

    setTimeout(()=>{
      this.spinnerService.hide();

       audiojs.events.ready(function() {
         var as = audiojs.createAll();
       });


    }, 500);
  }

  answerSingle(event: any){

    if(event.target.checked) {
      this.trainingService.setAnswered(event.target.value);
    }

    console.log(event);
  }

  answerMultiple(event: any){
    var _this = this;

    if(event.target.checked) {
      this.checkedList.push(event.target.value);
    }else{

      var value;
      var f: any;

      f = -1;

      value = event.target.value;

      var xquestion = this.checkedList.find(function(item, index) {
        f = index;
        return item  == value;
      });

      if (f != -1){
        this.checkedList.splice(f,1);
      }
    }


    this.correct_multiple = (this.checkedList.length === this.checkedListCorrect.length && this.checkedList.sort().every(function(value, index) { return value === _this.checkedListCorrect.sort()[index]}));


    console.log(this.correct_multiple);
  }

  bgColorMessage() {

    if (this.question.message.indexOf("mal") !== -1){
      this.message_bg = "#fdd3cb";
    }else{
      this.message_bg = "#CEF6D8";
    }

    const styles = {'background-color' : this.message_bg};
    return styles;
  }

  answerQuestion(){
     window.scroll(0,0);
    this.spinnerService.show();
    var timeNow: any;

    var _this = this;

    timeNow = new Date();

    var currentTime =  Math.floor((timeNow -  this.totalTime)/1000);

    var currentQuestion = this.trainingService.getCurrentQuestion();

    if (currentQuestion.type_question == "pildora"){
      //PILDORA
      console.log("PILDORA");
      this.trainingService.answeredPildora(currentQuestion.token, currentTime).subscribe(
          data => {
              console.log(data);


        });
       this.getNextView(currentQuestion);
    }else if(currentQuestion.type_question == 'multiple'){
      //MULTIPLE
      console.log("MULTIPLE");
      var answers;

      console.log(this.checkedList);
      answers = this.checkedList.join(",");

      if (answers){



      var correct = "false";

      if (this.correct_multiple){
        correct = "true";
      }

      this.trainingService.answeredMultiple(currentQuestion.token, answers, correct, currentTime).subscribe(
          data => {
              console.log(data);

              this.data_answered = data;

              setTimeout(()=>{
                this.spinnerService.hide();
              }, 500);

              if (this.feed.account.evaluation_on){
                this.getNextView(currentQuestion);
              }else{

                this.checkedListCorrect.forEach(function(entry) {
                    //console.log(entry);

                    if (_this.checkedList.indexOf(entry) > -1) {
                        _this.parcial_multiple = true;
                    }
                });

                if (this.correct_multiple){
                  this.parcial_multiple = false;
                }

                this.getSolution(currentQuestion.token, answers, this.data_answered.points, correct, this.parcial_multiple);
              }

          });

      }else{

        alert("Seleccione al menos una respuesta");
          setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);


          return false;

      }
    }else{

      console.log("NORMAL");

      var answer_correct = false;
      var _this = this;

      if (this.trainingService.getAnswered()){

      }else{


        alert("Seleccione una respuesta");
          setTimeout(()=>{
					this.spinnerService.hide();
				}, 500);


          return false;


      }
       currentQuestion.answers.find(function(item) {

        if (item.token == _this.trainingService.getAnswered()){
          if (item.correct){
            answer_correct = true;
          }
        }
      });

      var feed = this.trainingService.getCurrentFeed();

      console.log(feed.questions.length + "=====");

      if (feed.questions.length == 1){

         console.log( "IGUAL a 1");
        this.trainingService.answeredQuestion(currentQuestion.token, this.trainingService.getAnswered(), currentTime).subscribe(
          data => {
              this.data_answered = data;
               this.getNextView(currentQuestion);
            });


      }else{

            console.log( "NO ES IGUAL");
        this.trainingService.answeredQuestion(currentQuestion.token, this.trainingService.getAnswered(), currentTime).subscribe(
          data => {
              this.data_answered = data;
              this.getNextView(currentQuestion);
            });

      }




    }
  }

  getSolution(questionToken, answerToken, points, correct, parcial){
     window.scroll(0,0);
    this.router.navigate(["/training/answered", questionToken, { answer: answerToken, points: points, correct: correct, parcial: parcial}]);
  }

  getNextView(currentQuestion){


    this.trainingService.removeQuestion(currentQuestion);

    var f;

    var xquestion = this.questions.find(function(item, index) {
      f = index + 1;
      return item.token === currentQuestion.token;
    });

    var totalQuestions;

    totalQuestions = this.questions.length - 1;

    if (f <= totalQuestions){
      var xd = f - 1;

      this.nextQuestion = this.questions[f].token;


       window.scroll(0,0);
      this.router.navigate(["/training/question", this.nextQuestion]);
       setTimeout(()=>{
        this.spinnerService.hide();
      }, 100);
    }else{

      this.calculateNextView();

    }
  }
  getPercentage(){
   return this.questionPercentage;
  }

  getQuestion(){
    return this.question;
  }

  getChooseTitle(){


    if (this.getQuestion().choose_title != 0){
      var ctitle = "";

      switch(this.getQuestion().choose_title) {
      case "1":
        ctitle ="una";
        break;
      case "2":
        ctitle ="dos";
        break;
      case "3":
        ctitle ="tres";
        break;
      case "4":
        ctitle ="cuatro";
        break;
      case "5":
        ctitle ="cinco";
        break;
      case "6":
        ctitle ="seis";
        break;
      case "7":
        ctitle ="siete";
        break;
      case "8":
        ctitle ="ocho";
        break;
      case "9":
        ctitle ="nueve";
        break;
      case "10":
        ctitle ="diez";
        break;
      case "11":
        ctitle ="once";
        break;
      case "12":
        ctitle ="doce";
        break;
      case "13":
        ctitle ="trece";
        break;
      case "14":
        ctitle ="catorce";
        break;
      case "15":
        ctitle ="quince";
        break;
      case "16":
        ctitle ="dieciséis";
        break;
      case "17":
        ctitle ="diecisiete";
        break;
      case "18":
        ctitle ="dieciocho";
        break;
      case "19":
        ctitle ="diecinueve";
        break;
      case "20":
        ctitle ="veinte";
        break;

        default:
        ctitle ="una";
      }

      return "Elige " + ctitle + ":";
    }else{

      if (this.getQuestion().type_question == 'multiple'){
        return "Elige una o varias:";
      }else{
        return "Elige una:";

      }
    }

  }


  applyStyles() {
    //console.log("bg2 " + this.getBgcolor1());
    const styles = {'background-color' :  this.getBgcolor1(), 'position':'fixed', 'bottom':'0px','width': '100%', 'max-width':'700px', 'padding-left':'0px'};
    return styles;
  }




}
